export enum OrderLogEntryTitle {
  created = 'Bestellung erstellt',
  approvalWorkflowStarted = 'Genehmigungsprozess angestoßen',
  approvalWorkflowFinished = 'Genehmigungsprozess abgeschlossen',
  rejected = 'Bestellung abgelehnt',
  started = 'Bestellung wird ausgeführt',
  failed = 'Bestellung fehlgeschlagen',
  finished = 'Bestellung erfolgreich abgeschlossen',
  canceled = 'Bestellung abgebrochen',
}
