import {Pipe, PipeTransform} from '@angular/core';
import {SupplierProduct} from '../state/supplier-product.model';

@Pipe({
  name: 'supplierProductWithLowestBillingFrequencyPrice'
})
export class SupplierProductWithLowestBillingFrequencyPricePipe implements PipeTransform {
  transform(supplierProducts: SupplierProduct[]) {
    const hasProductWithBillingFrequencyPrice = supplierProducts.some(({billing_frequency_price}) =>
      billing_frequency_price !== null
    );

    if (!hasProductWithBillingFrequencyPrice) {
      return null;
    }

    return supplierProducts.reduce((cheapestProduct, supplierProduct) => {
      return supplierProduct.billing_frequency_price && +cheapestProduct.billing_frequency_price < +supplierProduct.billing_frequency_price
        ? cheapestProduct
        : supplierProduct;
    });
  }
}
