import {Component, OnInit} from '@angular/core';
import {MsalService} from '@azure/msal-angular';

@Component({
  selector: 'app-page-401',
  templateUrl: './page-401.component.html',
  styleUrls: ['./page-401.component.scss']
})
export class Page401Component implements OnInit {

  constructor(private msalService: MsalService) { }

  ngOnInit(): void {
  }

  login() {
    this.msalService.loginRedirect();
  }
}
