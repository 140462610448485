<div class="mat-typography page-container box-border pb-4 pt-14 flex flex-col items-center justify-center">
    <div class="mat-display-3">
        404 Lost in Space?
    </div>
    <img
      class="error-image"
      src="/assets/astro.svg"
      alt="Astronauten">
    <div class="mat-title error-message mt-4">
        Keine Sorge, das ist bloß ein 404-Fehler. Die von Ihnen gesuchte Seite existiert nicht oder wurde verschoben.
        Korrigieren Sie die eingegebene Adresse, oder kehren Sie zur vorherigen Seite zurück.
    </div>
    <a
      routerLink="/"
      mat-flat-button
      color="primary">
        zur Startseite
    </a>
</div>
