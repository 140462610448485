import {UiManagementQuery} from '@/management/state/ui-management.query';
import {UiShopQuery} from '@/shop/state/ui-shop.query';
import {Injectable} from '@angular/core';
import {combineQueries, Query} from '@datorama/akita';
import {map} from 'rxjs/operators';
import {ApprovalWorkflowsQuery} from './approval-workflows/approval-workflows.query';
import {BillingFrequenciesQuery} from './billing-frequencies/billing-frequencies.query';
import {CountryCodesQuery} from './country-codes/country-codes.query';
import {CurrencyCodesQuery} from './currency-codes/currency-codes.query';
import {LanguageCodesQuery} from './language-codes/language-codes.query';
import {MicrosoftGraphUsersQuery} from './microsoft-graph-users/microsoft-graph-users.query';
import {UiState, UiStore} from './ui.store';

@Injectable({providedIn: 'root'})
export class UiQuery extends Query<UiState> {
  navigationMode$ = this.select('navigationMode');
  isLoading$ = combineQueries([
    this.uiManagementQuery.isLoading$,
    this.uiShopQuery.isLoading$,
    this.approvalWorkflowsQuery.isLoading$,
    this.languageCodesQuery.selectLoading(),
    this.currencyCodesQuery.selectLoading(),
    this.countryCodesQuery.selectLoading(),
    this.billingFrequenciesQuery.selectLoading(),
    this.microsoftGraphUsersQuery.isLoading$,
  ])
    .pipe(map(([uiManagementLoading,
                 uiShopLoading,
                 approvalWorkflowsLoading,
                 languageCodesLoading,
                 currencyCodesLoading,
                 countryCodesLoading,
                 billingFrequenciesLoading,
                 microsoftGraphUsersLoading,
               ]) =>
      uiManagementLoading
      || uiShopLoading
      || approvalWorkflowsLoading
      || languageCodesLoading
      || currencyCodesLoading
      || countryCodesLoading
      || billingFrequenciesLoading
      || microsoftGraphUsersLoading
    ));

  constructor(
    protected store: UiStore,
    private uiManagementQuery: UiManagementQuery,
    private uiShopQuery: UiShopQuery,
    private approvalWorkflowsQuery: ApprovalWorkflowsQuery,
    private languageCodesQuery: LanguageCodesQuery,
    private currencyCodesQuery: CurrencyCodesQuery,
    private countryCodesQuery: CountryCodesQuery,
    private billingFrequenciesQuery: BillingFrequenciesQuery,
    private microsoftGraphUsersQuery: MicrosoftGraphUsersQuery,
  ) {
    super(store);
  }
}
