import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {ProductCategoryApproversState, ProductCategoryApproversStore} from './product-category-approvers.store';

@Injectable({providedIn: 'root'})
export class ProductCategoryApproversQuery extends QueryEntity<ProductCategoryApproversState> {
    constructor(protected store: ProductCategoryApproversStore) {
        super(store);
    }
}
