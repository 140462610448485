import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {OrderAvailableStatusMode} from '../../enums/order-available-status-mode.enum';
import {OrderAvailableStatusesState, OrderAvailableStatusesStore} from './order-available-statuses.store';
import {OrderStatusName} from '../enums/order-statuses.enum';

@Injectable({providedIn: 'root'})
export class OrderAvailableStatusesQuery extends QueryEntity<OrderAvailableStatusesState> {
  constructor(
    protected store: OrderAvailableStatusesStore
  ) {
    super(store);
  }

  getLabelByStatusIdAndType(statusName: string, orderAvailableStatusMode: string) {
    let statusLabel = 'Undefiniert';
    if (orderAvailableStatusMode === OrderAvailableStatusMode.purchaser) {
      switch (statusName) {
        case OrderStatusName.new:
          statusLabel = 'Bestellt';
          break;
        case OrderStatusName.approved:
        case OrderStatusName.finished:
          statusLabel = 'Genehmigt';
          break;
        case OrderStatusName.rejected:
          statusLabel = 'Abgelehnt';
          break;
        case OrderStatusName.canceled:
          statusLabel = 'Abgebrochen';
          break;
        default:
          statusLabel = 'In Prüfung';
          break;
      }
    } else if (orderAvailableStatusMode === OrderAvailableStatusMode.overview) {
      switch (statusName) {
        case OrderStatusName.new:
          statusLabel = 'Bestellung aufgegeben';
          break;
        case OrderStatusName.approverProductCategories:
        case OrderStatusName.approverLevel1:
        case OrderStatusName.approverLevel2:
          statusLabel = 'Genehmigungsworkflow läuft';
          break;
        case OrderStatusName.approved:
        case OrderStatusName.finished:
          statusLabel = 'Auftrag bestätigt';
          break;
        case OrderStatusName.rejected:
          statusLabel = 'Auftrag abgelehnt';
          break;
        case OrderStatusName.canceled:
          statusLabel = 'Auftrag abgebrochen';
          break;
        default:
          statusLabel = 'Status unbekannt';
          break;
      }
    } else if (orderAvailableStatusMode === OrderAvailableStatusMode.progressBar) {
      switch (statusName) {
        case OrderStatusName.new:
          statusLabel = '10';
          break;
        case OrderStatusName.approverProductCategories:
        case OrderStatusName.approverLevel1:
        case OrderStatusName.approverLevel2:
          statusLabel = '50';
          break;
        case OrderStatusName.approved:
        case OrderStatusName.finished:
          statusLabel = '100';
          break;
        case OrderStatusName.rejected:
        case OrderStatusName.canceled:
          statusLabel = '-1';
          break;
        default:
          statusLabel = '0';
          break;
      }
    } else {
      // TODO Implement all possible statuses if required
      statusLabel = 'Bitte prüfen!';
    }

    return statusLabel;
  }
}
