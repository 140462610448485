import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Faq } from './faq.model';

export interface FaqsState extends EntityState<Faq> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'faqs' })
export class FaqsStore extends EntityStore<FaqsState> {

  constructor() {
    super();
  }

}
