import {Injectable} from '@angular/core';
import {filterNilValue, QueryEntity} from '@datorama/akita';
import {
  SupplierProductAvailableStatusesState,
  SupplierProductAvailableStatusesStore
} from './supplier-product-available-statuses.store';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class SupplierProductAvailableStatusesQuery extends QueryEntity<SupplierProductAvailableStatusesState> {
  supplierProductAvailableStatuses$ = this.selectAll();

  constructor(protected store: SupplierProductAvailableStatusesStore) {
    super(store);
  }

  selectStatusById(statusId: number) {
    return this.selectAll().pipe(
      filterNilValue(),
      map(statuses => statuses.find(status => status.status_id === statusId))
    );
  }

  getStatusIdByName(name: string) {
    return this.getAll()
      .filter((availableStatus) => availableStatus?.status?.name === name)
      .map((availableStatus) => availableStatus?.status?.id).pop();
  }
}
