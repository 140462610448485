import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {ProductAttributeGroupsState, ProductAttributeGroupsStore} from './product-attribute-groups.store';

@Injectable({providedIn: 'root'})
export class ProductAttributeGroupsQuery extends QueryEntity<ProductAttributeGroupsState> {

  constructor(protected store: ProductAttributeGroupsStore) {
    super(store);
  }

  productAttributeGroups$ = this.selectAll();
}
