import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig,} from '@datorama/akita';
import {CostCenterProductCategory, CostCenterProductCategoryPivot} from './cost-center-product-category-pivot.model';

export interface CostCenterProductCategoryPivotsState extends EntityState<CostCenterProductCategoryPivot>, ActiveState {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'costCenterProductCategoryPivots'})
export class CostCenterProductCategoryPivotsStore extends EntityStore<CostCenterProductCategoryPivotsState> {
  constructor() {
    super({loading: false});
  }
}
