import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {TenantImage} from './tenant-image.model';

export interface TenantImagesState extends EntityState<TenantImage> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'order-images', idKey: 'tenant_id'})
export class TenantImagesStore extends EntityStore<TenantImagesState> {

  constructor() {
    super({loading: false});
  }
}
