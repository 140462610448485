<div [class]="'layout-container mat-typography' + (user ? '' : ' hide')">

    <app-header/>

    <app-subheader
      [isSidenavOpen]="isSidenavOpen"
      (sideNavToggled)="sidenavToggle()"/>

    <mat-drawer-container
      class="sidenav-container"
      [style.marginTop.px]="toolBarHeight">
        <mat-drawer
          #drawer
          class="sidenav mat-elevation-z10"
          mode="side"
          [opened]="isSidenavOpen">
            <app-sidenav [isSidenavOpen]="isSidenavOpen"></app-sidenav>
        </mat-drawer>

        <mat-drawer-content>
            <div id="page-container">
                <main
                  class="main-container"
                  id="content-wrap">
                    <router-outlet></router-outlet>
                </main>

                <app-footer/>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
