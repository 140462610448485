import {Pipe, PipeTransform} from '@angular/core';
import {getDefaultImageUrl} from '../utils';

@Pipe({
  name: 'productDefaultImageUrl'
})
export class ProductDefaultImageUrlPipe implements PipeTransform {
  transform(images: any, ...args: unknown[]) {
    return getDefaultImageUrl(images);
  }
}
