import {calculateBillingFrequencyPriceTotal} from '../../../../shared/helpers/calculate-billing-frequency-price-total';
import {calculateUnitPriceTotal} from '../../../../shared/helpers/calculate-unit-price-total';
import {ShoppingCartItemsForSupplier, ShoppingCartItemWithSupplierProduct} from '../../shopping-cart.model';

export const groupShoppingCartItemsBySuppliers = (shoppingCartItems: ShoppingCartItemWithSupplierProduct[]) => {
  const suppliers = shoppingCartItems
    .map(shoppingCartItem => ({id: shoppingCartItem.supplier_id, name: shoppingCartItem.supplier?.name}))
    // Remove duplicates
    .filter((supplier, index, self) =>
      index === self.findIndex(shoppingCartItemSupplier => (shoppingCartItemSupplier.id === supplier.id))
    );
  const shoppingCartItemsGroupBySuppliers: ShoppingCartItemsForSupplier[] = [];

  for (const supplier of suppliers) {
    const shoppingCartItemsOfSupplier = shoppingCartItems.filter(shoppingCartItem => shoppingCartItem.supplier_id === supplier.id);
    const unitPrices = shoppingCartItemsOfSupplier.map(({quantity, unit_price}) => quantity * unit_price);
    const billingFrequencyPrices = shoppingCartItemsOfSupplier.map(
      ({quantity, billing_frequency_price}) => quantity * billing_frequency_price
    );

    shoppingCartItemsGroupBySuppliers.push({
      supplier_id: supplier.id,
      supplier_name: supplier.name,
      unitPriceTotal: calculateUnitPriceTotal(unitPrices),
      billingFrequencyPriceTotal: calculateBillingFrequencyPriceTotal(billingFrequencyPrices),
      shoppingCartItems: shoppingCartItemsOfSupplier
    });
  }

  return shoppingCartItemsGroupBySuppliers;
};
