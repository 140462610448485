import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ApproversStore, ApproversState } from './approvers.store';

@Injectable({ providedIn: 'root' })
export class ApproversQuery extends QueryEntity<ApproversState> {

  constructor(protected store: ApproversStore) {
    super(store);
  }

}
