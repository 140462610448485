import {Supplier} from '@/management/supplier/state/supplier.model';

export type SupplierInformationForMinimumOrderValueSurcharge = Pick<
  Supplier,
  'minimum_order_value' |
  'minimum_order_value_surcharge'
>;

export const calculateMinimumOrderValueSurcharge = (supplier: SupplierInformationForMinimumOrderValueSurcharge, unitPriceTotal: number) => {
  const {minimum_order_value, minimum_order_value_surcharge} = supplier;

  if (!minimum_order_value || !minimum_order_value_surcharge || unitPriceTotal >= minimum_order_value) {
    return 0;
  }

  return minimum_order_value_surcharge;
}
