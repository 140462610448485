<mat-toolbar
  color="primary"
  class="fix-nav flex">
    <div class="flex flex flex-1 justify-start items-center w-full h-full">
        <button
          type="button"
          mat-icon-button
          class="waffle"
          color="primary"
          matTooltip="">
            <a
              [href]="userTenantNavigationUrl"
              target="_blank">
                <img src="../../../../assets/icon_waffle.png"/>
            </a>
        </button>
        <img
          *ngIf="tenantId"
          class="logo"
          [src]="(tenantImagesQuery.getDefaultImageUrlByTenantId(tenantId) | async)"/>
        <span
          class="title"
          matTooltip="zur Startseite"
          routerLink="/home">{{
            title
            }}</span>
    </div>

    <div
      *ngIf="environment.searchActive"
      class="search flex-1">
        <form>
            <mat-form-field
              appearance="legacy"
              floatLabel="never">
                <input
                  matInput
                  [formControl]="searchControl"
                  (keydown.enter)="navigateToSearchResults()"
                  placeholder="Alle Produkte durchsuchen"/>
                <span matPrefix><mat-icon>search</mat-icon></span>
            </mat-form-field>
        </form>
    </div>

    <div class="flex flex-1 justify-end items-center">
        <button
          *ngIf="environment.shopActive"
          type="button"
          mat-icon-button
          class="cart"
          color="primary"
          matTooltip="Einkaufswagen anzeigen"
          (click)="navigateToShoppingCart()">
            <mat-icon>shopping_cart</mat-icon>
            <span class="new badge">{{ shoppingCartItemCount$ | async }}</span>
        </button>
        <button
          [matTooltip]="userName + ' (' + userEmail + ')'"
          class="profile"
          color="primary"
          mat-icon-button
          [matMenuTriggerFor]="profileMenu"
          type="button">
            <img src="/assets/icon_profile_default.svg"/>
        </button>
        <mat-menu
          #profileMenu="matMenu"
          class="mat-typography profile-menu">
            <div>
                <div class="mb-2 flex flex-col items-center justify-center">
                    <img
                      class="flex"
                      src="/assets/icon_profile_default.png"/>
                    <div
                      class="flex"
                      class="profile-user-name">
                        {{ userName }}
                    </div>
                    <div
                      class="flex"
                      class="profile-tenant-name">
                        {{ tenantName }}
                    </div>
                    <div
                      class="flex"
                      class="profile-user-email">
                        {{ userEmail }}
                    </div>
                </div>
                <a
                  class="!flex justify-start items-center"
                  mat-menu-item
                  href="https://myaccount.microsoft.com/"
                  target="_blank">
                    <div class="w-full flex items-center justify-center">
                        <div class="flex">Account anzeigen</div>
                        <div class="flex flex-1 justify-end items-center">
                            <mat-icon class="material-icons-outlined">account_circle</mat-icon>
                        </div>
                    </div>
                </a>

                <mat-divider></mat-divider>

                <a
                  class="!flex justify-start items-center"
                  mat-menu-item
                  href="https://portal.office.com"
                  target="_blank">
                    <div class="w-full flex items-center justify-center">
                        <div class="flex">Office-Portal</div>
                        <div class="flex flex-1 justify-end items-center">
                            <mat-icon class="material-icons-outlined">apps</mat-icon>
                        </div>
                    </div>
                </a>

                <mat-divider></mat-divider>

                <button
                  type="button"
                  mat-menu-item
                  (click)="logout()"
                  color="primary">
                    <div class="flex">
                        <div class="flex-1">Abmelden</div>
                        <div class="flex flex-1 justify-end items-center">
                            <mat-icon class="material-icons-outlined">lock</mat-icon>
                        </div>
                    </div>
                </button>
            </div>
        </mat-menu>
    </div>
</mat-toolbar>
