import {Supplier} from '../../../../supplier/state/supplier.model';
import {Status} from '@/shared/state/status/status.model';

export const addStatusToSupplierProduct = (suppliers: Supplier[], statuses: Status[]) => suppliers.map(supplier => ({
    ...supplier,
    pivot: {
      ...supplier.pivot,
      status: statuses.find(({id}) => id === supplier.pivot.status_id)
    }
  } as Supplier)
);
