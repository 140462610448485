import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {OrderItemsState, OrderItemsStore} from './order-items.store';

@Injectable({providedIn: 'root'})
export class OrderItemsQuery extends QueryEntity<OrderItemsState> {
  orderItems$ = this.selectAll();
  activeOrderItems$ = this.selectActive();
  activeOrderItem$ = this.getActive();

  constructor(protected store: OrderItemsStore) {
    super(store);
  }
}
