import {SessionQuery} from '@/core/session/state/session.query';
import {inject, Injectable} from '@angular/core';
import {filterNilValue, QueryEntity} from '@datorama/akita';
import {map, switchMap} from 'rxjs/operators';
import {ProductCategoryTenant} from './product-category-tenant.model';
import {ProductCategoryTenantsState, ProductCategoryTenantsStore,} from './product-category-tenants.store';

@Injectable({providedIn: 'root'})
export class ProductCategoryTenantsQuery extends QueryEntity<ProductCategoryTenantsState> {
  private readonly sessionQuery = inject(SessionQuery);

  productCategoryTenants$ = this.selectAll();
  productCategoryTenantForTenant$ = this.sessionQuery.tenant$.pipe(
    filterNilValue(),
    switchMap(tenant => this.selectProductCategoryTenantsByTenant(tenant.id)),
  );
  /** Quick reference list of available product category ids for the active tenant. */
  productCategoryTenantProductCategoryIdsForTenant$ = this.productCategoryTenantForTenant$.pipe(
    map(productCategoryTenants => productCategoryTenants.map(({product_category_id}) => product_category_id)),
  );

  constructor(protected store: ProductCategoryTenantsStore) {
    super(store);
  }

  selectProductCategoryTenantsByTenant(tenantId: ProductCategoryTenant['tenant_id']) {
    return this.productCategoryTenants$.pipe(
      map(productCategoryTenants =>
        productCategoryTenants.filter(productCategoryTenant => productCategoryTenant.tenant_id === tenantId)
      ),
    );
  }
}
