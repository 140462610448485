import {Permission} from '../../permission.model';

export const doesPermissionMatch = (permissionToCheck: Partial<Permission>, permission: Permission, allowedScopes: string[]) => {
  const {model, attribute, action, scope} = permission;

  const modelIsMatching = (!permissionToCheck?.model || (model === (permissionToCheck?.model ?? null)));
  // HINT: Attribute check for "parent permission" (e.g. shop-tenant-use -> shop:myOrders-tenant-use) is disabled for all "use permissions"
  const attributeIsMatching = (!permissionToCheck?.attribute || ((!attribute && permissionToCheck.action !== 'use') || attribute === (permissionToCheck?.attribute ?? null)));
  const scopeIsMatching = (!permissionToCheck?.scope || (allowedScopes?.includes(scope) ?? null));
  const actionIsMatching = (!permissionToCheck?.action || (action === (permissionToCheck?.action ?? null)));

  return modelIsMatching
    && attributeIsMatching
    && scopeIsMatching
    && actionIsMatching;
};
