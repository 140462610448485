import {StatusNames} from '@/shared/state/status/enums/status-names.enum';
import {Injectable} from '@angular/core';
import {filterNilValue, QueryEntity} from '@datorama/akita';
import {map} from 'rxjs/operators';
import {findStatusByName} from './helpers/find-status-by-name';
import {SupplierAvailableStatusesState, SupplierAvailableStatusesStore,} from './supplier-available-statuses.store';

@Injectable({providedIn: 'root'})
export class SupplierAvailableStatusesQuery extends QueryEntity<SupplierAvailableStatusesState> {
  supplierAvailableStatuses$ = this.selectAll();

  constructor(protected store: SupplierAvailableStatusesStore) {
    super(store);
  }

  selectStatusByName(name: StatusNames) {
    return this.supplierAvailableStatuses$.pipe(
      filterNilValue(),
      map(availableStatuses => findStatusByName(availableStatuses, name)),
    );
  }
}
