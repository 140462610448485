import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'germanNumberStringToNumber'
})
export class GermanNumberStringToNumberPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): number {
    // Remove all dots
    value = value ? value.replace(/\./gi, '') : null;
    // Change comma to dot
    value = value ? value.replace(',', '.') : null;
    return value || value == '0' ? parseFloat(value) : null;
  }
}
