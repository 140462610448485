import {SessionQuery} from '@/core/session/state/session.query';
import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {switchMap, timer} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ShopRouteNames} from '../../constants/routes.constants';
import {FaqRouteNames} from '../constants';
import {FaqsStore} from './faqs.store';

@Injectable({providedIn: 'root'})
export class FaqsService {
  private readonly faqsStore = inject(FaqsStore);
  private readonly router = inject(Router);
  private readonly sessionQuery = inject(SessionQuery);

  get() {
    return timer(500).pipe(
      switchMap(() => this.sessionQuery.tenantSupportContactMail$),
      tap(tenantSupportContactMail => {
        const faqs = [
          {
            id: 1,
            question: 'Wo kann ich den Status meiner Bestellung einsehen?',
            answer: 'Ein Überblick über den aktuellen Stand Ihrer Bestellung finden Sie auf Ihrer "<a href=\'/#/shop/order/list\'>Meine Aufträge</a>" Übersichtsseite. Detaillierte Informationen zum Status Ihrer Bestellung, sowie die nächsten Schritte finden Sie auf der jeweiligen Auftragsseite. Diese erreichen Sie ebenfalls über "<a href=\'/#/shop/order/list\'>Meine Aufträge</a>".'
          },
          {
            id: 2,
            question: 'Warum wurde meine Bestellung abgelehnt?',
            answer: 'Für genaue Informationen zur Ablehnung Ihrer Bestellung wenden Sie sich bitte an Ihren Kostenstellenverantwortlichen.'
          },
          {
            id: 3,
            question: 'Kann ich Details meiner Bestellung ändern, nachdem diese abgeschickt wurde?',
            answer: `Sobald Sie eine Bestellung ausgelöst haben, wir im System automatisch ein Auftrag generiert. Sollten Sie einen Artikel oder Service vergessen haben, lösen Sie bitte eine neue Bestellung aus. Sollten Sie einen Artikel falsch bestellt haben, so Informieren Sie uns bitte per <a href="mailto:${tenantSupportContactMail}?subject=Stornierung%20-%20Einkaufsplattform%20Shop">Mail</a>. Bitte geben Sie hier Ihre Bestellnummer an.`
          },
          {
            id: 4,
            question: 'Wo finde ich meine Bestellnummer?',
            answer: 'Die Bestellnummer finden Sie direkt in Ihren Bestellungen. Dorthin gelangen Sie über "<a href=\'/#/shop/order/list\'>Meine Aufträge</a>".'
          },
          {
            id: 5,
            question: 'Wie kann ich als GenehmigerIn zwischen meinen eigenen Bestellungen und den, von mir zu genehmigenden, wechseln?',
            answer: 'Unter "<a href=\'/#/shop/order/list\'>Meine Aufträge</a>" finden Sie im oberen Bereich auf der rechten Seite einen Schalte, mit dem Sie zwischen Ihren eigenen Bestellungen und den zu genehmigenden Bestellungen umschalten können.'
          },
          {
            id: 6,
            question: 'Wie lege ich einen Artikel an?',
            answer: `Sollten Sie Artikel oder Services vermissen, schreiben Sie uns bitte eine <a href="mailto:${tenantSupportContactMail}?subject=Neuer%20Artikel/Service%20-%20Einkaufsplattform%20Shop">Nachricht</a> mit allen relevanten Informationen.`
          },
          {
            id: 7,
            question: 'Wie lege ich einen Lieferanten an?',
            answer: `Sollten Sie Artikel oder Services vermissen, schreiben Sie uns bitte eine <a href="mailto:${tenantSupportContactMail}?subject=Neuer%20Artikel/Service%20-%20Einkaufsplattform%20Shop">Nachricht</a> mit allen relevanten Informationen.`
          }
        ];

        this.faqsStore.set(faqs);
      })
    );
  }

  navigateToFaq(): Promise<boolean> {
    return this.router.navigate([ShopRouteNames.SHOP + '/' + FaqRouteNames.FAQ]);
  }
}
