import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PermissionsStore, PermissionsState } from './permissions.store';
import {Permission} from './permission.model';
import {getAllowedScopeForPermission} from './helpers/functions/getAllowedScopeForPermission';
import {doesPermissionMatch} from './helpers/functions/doesPermissionMatch';
import {distinctUntilChanged, map} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PermissionsQuery extends QueryEntity<PermissionsState> {
  isLoading$ = this.selectLoading();

  permissions$ = this.selectAll();

  constructor(protected store: PermissionsStore) {
    super(store);
  }

  getHasOneOrMorePermissionToInStore(permissionsToCheck: Partial<Permission> | Partial<Permission>[]) {
    if (!Array.isArray(permissionsToCheck)) {
      permissionsToCheck = [permissionsToCheck];
    }

    for (const permissionToCheck of permissionsToCheck) {
      const allowedScopes = getAllowedScopeForPermission(permissionToCheck);

      // Check if entry in store exists matching the exact permission
      if (permissionToCheck?.id && this.hasEntity(permissionToCheck.id)) {
        return true;
      }

      // Check if entry in store exists matching the permission to check
      if (this.hasEntity(permission => doesPermissionMatch(permissionToCheck, permission, allowedScopes))) {
        return true;
      }
    }

    return false;
  }

  selectHasOneOrMorePermissionTo(permissionsToCheck: Partial<Permission> | Partial<Permission>[]) {
    return this.permissions$.pipe(
      map(permissions => {
          // HINT: Array check has to be inside to stop type problems with array type
          if (!Array.isArray(permissionsToCheck)) {
            permissionsToCheck = [permissionsToCheck];
          }

          for (const permissionToCheck of permissionsToCheck) {
            const allowedScopes = getAllowedScopeForPermission(permissionToCheck);

            // Check if entry in store exists matching the exact permission
            if (permissionToCheck?.id && permissions.some(permission => permission.id === permissionToCheck.id)) {
              return true;
            }

            // Check if entry in store exists matching the permission to check
            if (permissions.some(permission => doesPermissionMatch(permissionToCheck, permission, allowedScopes))) {
              return true;
            }
          }

          return false;
        }
      ),
      distinctUntilChanged()
    );
  }
}
