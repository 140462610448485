import {ImageUrl} from '../image-urls/image-url.model';

export interface TenantImage {
  tenant_id: number | string;
  name: string;
  urls: ImageUrl;
}

export function createTenantImage(params: Partial<TenantImage>) {
  return {
    ...params
  } as TenantImage;
}
