import { Injectable } from '@angular/core';
import { LanguageCode } from './language-code.model';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

export interface LanguageCodesState
  extends EntityState<LanguageCode>,
    ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'languageCodes' })
export class LanguageCodesStore extends EntityStore<LanguageCodesState> {
  constructor() {
    super({loading: false});
  }
}
