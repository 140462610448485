export enum OrderActivityName {
  created = 'created',
  canceled = 'canceled',
  rejected = 'rejected',
  approved = 'approved',
  finished = 'finished',
  failed = 'failed',
  started = 'started',
  approvalWorkflowStarted = 'approval-workflow-started',
  approvalWorkflowActivity = 'approval-workflow-activity',
  approvalWorkflowFinished = 'approval-workflow-finished',
}
