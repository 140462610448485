import {AllGuard} from '@/core/session/guards/all/all.guard';
import {PermissionGuard} from '@/core/session/guards/permission/permission.guard';
import {Permission} from '@/core/session/state/permissions/permission.model';
import {HomeComponent, HomeRouteNames} from '@/home';
import {ManagementRouteNames} from '@/management/constants/routes.constants';
import {ImprintComponent, Page401Component, PrivacyPolicyComponent} from '@/shared';
import {SharedRouteNames} from '@/shared/constants';
import {LayoutComponent} from '@/shared/layout/layout.component';
import {Page404Component} from '@/shared/pages/page-404/page-404.component';
import {ShopRouteNames} from '@/shop/constants/routes.constants';
import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {BrowserUtils} from '@azure/msal-browser';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivateChild: [AllGuard],
    children: [
      {
        path: '',
        canActivateChild: [PermissionGuard],
        children: [
          {
            path: '',
            redirectTo: HomeRouteNames.HOME,
            pathMatch: 'full'
          },
          {
            path: HomeRouteNames.HOME,
            data: {module: ShopRouteNames.SHOP},
            component: HomeComponent
          },
          {
            path: ShopRouteNames.SHOP,
            data: {
              module: ShopRouteNames.SHOP,
              permissions: [{model: ShopRouteNames.SHOP, scope: 'tenant', action: 'use'} as Permission]
            },
            loadChildren: () =>
              import('@/shop/shop.module').then((m) => m.ShopModule)
          },
          {
            path: ManagementRouteNames.MANAGEMENT,
            data: {
              module: ManagementRouteNames.MANAGEMENT,
              permissions: [{model: ManagementRouteNames.MANAGEMENT, scope: 'tenant', action: 'use'} as Permission]
            },
            loadChildren: () =>
              import('@/management/management.module').then(
                (m) => m.ManagementModule
              )
          },
          {
            path: SharedRouteNames.IMPRINT,
            component: ImprintComponent,
            data: {module: ShopRouteNames.SHOP},
            pathMatch: 'full'
          },
          {
            path: SharedRouteNames.PRIVACY_POLICY,
            component: PrivacyPolicyComponent,
            data: {module: ShopRouteNames.SHOP},
            pathMatch: 'full'
          },
          {
            path: '404',
            component: Page404Component,
            data: {module: ShopRouteNames.SHOP},
          },
          {
            path: '**',
            redirectTo: '/404',
            pathMatch: 'full'
          }
        ]
      }
    ]
  },
  {
    path: '401',
    component: Page401Component
  },
  // Needed for msal login error
  {
    path: 'error',
    component: Page401Component
  },
  {
    path: '404',
    component: Page404Component
  },
  {
    path: '**',
    redirectTo: '/404',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
      // Disabling the initial navigation is necessary to prevent routing while msal login/token fetching is in process
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledBlocking' : 'disabled', // Remove this line to use Angular Universal
      // scrollPositionRestoration: 'enabled', // Does not work because of 'mat-drawer-content' (neither does value 'top')
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
