import {inject, Injectable} from '@angular/core';
import {ID} from '@datorama/akita';
import {createHttpIncludes} from '../../utils/functions/http-params';
import {Approver} from './approver.model';
import {ApproversStore} from './approvers.store';

@Injectable({providedIn: 'root'})
export class ApproversService {
  static readonly includes = createHttpIncludes([
    'approvalLevel',
    'user',
    'tenant',
  ]);

  private readonly approversStore = inject(ApproversStore);

  add(approver: Approver) {
    this.approversStore.add(approver);
  }

  update(id, approver: Partial<Approver>) {
    this.approversStore.update(id, approver);
  }

  remove(id: ID) {
    this.approversStore.remove(id);
  }

}
