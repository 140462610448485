<div class="page-container mat-typography flex flex-col items-center justify-center">
    <div class="flex items-center justify-center">
        <!--    <div class="icon">-->
        <!--      <img class="icon" src="../../../../assets/logo_einkaufsportal.svg"/>-->
        <!--    </div>-->

        <div class="app-title mat-line flex items-center justify-center">
            <b>Einkaufsplattform</b>
        </div>
    </div>

    <div class="mat-headline">
        401
    </div>

    <div class="mat-title">
        Ihr Benutzer ist auf unserer Umgebung nicht registriert. Bitte melden Sie sich mit einem registrierten Benutzer
        an.
    </div>

    <button
      type="button"
      mat-flat-button
      color="primary"
      (click)="login()">
        Mit einem anderen Benutzer einloggen
    </button>
</div>
