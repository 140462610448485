import { Injectable } from '@angular/core';
import { SupplierAvailableStatus } from './supplier-available-status.model';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

export interface SupplierAvailableStatusesState
  extends EntityState<SupplierAvailableStatus>,
    ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'supplierAvailableStatuses' })
export class SupplierAvailableStatusesStore extends EntityStore<
  SupplierAvailableStatusesState
> {
  constructor() {
    super({loading: false});
  }
}
