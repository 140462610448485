import {Injectable} from '@angular/core';
import {combineQueries, Query} from '@datorama/akita';
import {UiManagementState, UiManagementStore} from './ui-management.store';
import {SuppliersQuery} from '../supplier/state/suppliers.query';
import {
  SupplierAvailableStatusesQuery
} from '../supplier/state/supplier-available-statuses/supplier-available-statuses.query';

import {ProductsQuery} from '../product/state/products.query';
import {map} from 'rxjs/operators';
import {SupplierProductsQuery} from '../product/state/supplier-products/supplier-products.query';
import {
  SupplierProductAvailableStatusesQuery
} from '../product/state/supplier-product-available-statuses/supplier-product-available-statuses.query';
import {QuantityUnitsQuery} from '../product/state/quantity-units/quantity-units.query';
import {ProductCategoriesQuery} from '../product/state/product-categories/product-categories.query';
import {
  ProductAttributeTemplatesQuery
} from '../product/state/product-attribute-templates/product-attribute-templates.query';
import {ProductAttributeGroupsQuery} from '../product/state/product-attribute-groups/product-attribute-groups.query';

@Injectable({providedIn: 'root'})
export class UiManagementQuery extends Query<UiManagementState> {
  isLoading$ = combineQueries([
    this.suppliersQuery.selectLoading(),
    this.supplierProductsQuery.selectLoading(),
    this.supplierAvailableStatusesQuery.selectLoading(),
    this.supplierProductAvailableStatusesQuery.selectLoading(),
    this.quantityUnitsQuery.selectLoading(),
    this.productCategoriesQuery.selectLoading(),
    this.productAttributeTemplatesQuery.selectLoading(),
    this.productAttributeGroupsQuery.selectLoading(),
    this.productsQuery.selectLoading(),
  ]).pipe(
    map(loadingStates => loadingStates.includes(true)),
  );

  constructor(
    protected store: UiManagementStore,
    private suppliersQuery: SuppliersQuery,
    private supplierProductsQuery: SupplierProductsQuery,
    private supplierAvailableStatusesQuery: SupplierAvailableStatusesQuery,
    private supplierProductAvailableStatusesQuery: SupplierProductAvailableStatusesQuery,
    private quantityUnitsQuery: QuantityUnitsQuery,
    private productCategoriesQuery: ProductCategoriesQuery,
    private productAttributeTemplatesQuery: ProductAttributeTemplatesQuery,
    private productAttributeGroupsQuery: ProductAttributeGroupsQuery,
    private productsQuery: ProductsQuery,
  ) {
    super(store);
  }
}
