import {SessionQuery} from '@/core/session/state/session.query';
import {Approver} from '@/shared/state/approvers/approver.model';
import {ApproversQuery} from '@/shared/state/approvers/approvers.query';
import {ApproversService} from '@/shared/state/approvers/approvers.service';
import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {
  createHttpIncludes,
  getHttpOptionsWithInclude,
  getHttpOptionsWithParams
} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {Router} from '@angular/router';
import {arrayAdd, ID, setLoading} from '@datorama/akita';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {ManagementRouteNames} from '../../constants/routes.constants';
import {
  CostCenterProductCategoryPivotsService
} from '../../product/state/cost-center-product-category/cost-center-product-category-pivots.service';
import {CostCenterRouteNames} from '../constants';
import {CostCenter} from './cost-center.model';
import {CostCentersStore} from './cost-centers.store';

@Injectable({providedIn: 'root'})
export class CostCentersService {
  static readonly includes = createHttpIncludes([
    'productCategories',
  ])

  private readonly costCentersStore = inject(CostCentersStore);
  private readonly approversService = inject(ApproversService);
  private readonly approversQuery = inject(ApproversQuery);
  private readonly costCenterProductCategoryPivotsService = inject(CostCenterProductCategoryPivotsService);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);
  private readonly router = inject(Router);
  private readonly snackBar = inject(MatSnackBar);

  get() {
    const options = getHttpOptionsWithInclude(CostCentersService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http.get<ApiResponse<CostCenter[]>>(environment.api.baseUrl + 'cost-centers', options)
      .pipe(
        setLoading(this.costCentersStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.costCentersStore)),
        tap((response) => {
          this.costCentersStore.set(response['data']);
        }),
      );
  }

  getById(id: number) {
    const options = getHttpOptionsWithInclude(CostCentersService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http.get<ApiResponse<CostCenter>>(environment.api.baseUrl + 'cost-centers/' + id, options)
      .pipe(
        setLoading(this.costCentersStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.costCentersStore)),
        tap(({data: costCenter}) => {
          const {product_categories, ...remainingCostCenter} = costCenter;

          this.costCentersStore.upsert(costCenter.id, remainingCostCenter);
          this.costCenterProductCategoryPivotsService.setFromProductCategoryCostCenters(product_categories);
        }),
      );
  }

  getApproversOfCostCenterById(id: number) {
    const options = getHttpOptionsWithInclude(ApproversService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .get<ApiResponse<Approver[]>>(environment.api.baseUrl + 'cost-centers/' + id + '/approvers', options)
      .pipe(
        setLoading(this.costCentersStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.costCentersStore)),
        tap((response) => {
          if (!response?.data) {
            return;
          }

          for (const approver of response.data) {
            if (this.approversQuery.hasEntity(approver.id)) {
              this.approversService.update(approver.id, approver);
            } else {
              this.approversService.add(approver);
            }
          }
        }),
      );
  }

  add(costCenter: CostCenter) {
    this.costCentersStore.update(costCenter);
  }

  addOnDb(costCenter: CostCenter): Observable<ApiResponse<CostCenter>> {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .post<ApiResponse<CostCenter>>(environment.api.baseUrl + 'cost-centers', costCenter, options)
      .pipe(
        setLoading(this.costCentersStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.costCentersStore)),
        tap((response) => {
          this.add(response['data']);
          this.snackBar.open('Kostenstelle wurde erfolgreich gespeichert!', 'Schließen', {panelClass: 'snackbar-success'});
        }),
      );
  }

  addApproverToCostCenterOnDb(id: number, approver: Approver) {
    const options = getHttpOptionsWithInclude(ApproversService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http.post<ApiResponse<Approver>>(
      environment.api.baseUrl + 'cost-centers/' + id + '/approvers',
      approver,
      options
    ).pipe(
      setLoading(this.costCentersStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.costCentersStore)),
      tap((response) => {
        // HINT: Validate 'duplicate' usage of cost center approvers in cost center store
        // HINT: Validate only 'adding' cost center approvers to cost center store (no updates or deletes)
        // Add approver to existing cost center in store
        this.costCentersStore.update(
          id,
          ({approvers}) => ({
            approvers: arrayAdd(
              approvers,
              response.data
            )
          })
        );

        // Add approver to approvers store
        this.approversService.add(response.data);
      }),
    );
  }

  update(id, costCenter: Partial<CostCenter>) {
    this.costCentersStore.update(id, costCenter);
  }

  updateOnDb(costCenter: CostCenter): Observable<ApiResponse<CostCenter>> {
    const options = getHttpOptionsWithInclude(CostCentersService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .put<ApiResponse<CostCenter>>(environment.api.baseUrl + 'cost-centers/' + costCenter.id, costCenter, options)
      .pipe(
        setLoading(this.costCentersStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.costCentersStore)),
        tap((response) => {
            this.costCentersStore.update(response['data']);
            this.snackBar.open('Kostenstelle wurde erfolgreich gespeichert!', 'Schließen', {panelClass: 'snackbar-success'});
          }
        ),
      );
  }

  updateApproverFromCostCenterOnDb(id: number, approver: Approver): Observable<ApiResponse<Approver>> {
    const options = getHttpOptionsWithInclude(ApproversService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http.put<ApiResponse<Approver>>(
      environment.api.baseUrl + 'cost-centers/' + id + '/approvers/' + approver.id,
      approver,
      options
    ).pipe(
      setLoading(this.costCentersStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.costCentersStore)),
      tap((response) => {
        // Update approver to approvers store
        if (!response?.data) {
          return;
        }

        if (this.approversQuery.hasEntity(response.data.id)) {
          this.approversService.update(response.data.id, response.data);
        } else {
          this.approversService.add(response.data);
        }
      }),
    );
  }

  remove(id: ID) {
    this.costCentersStore.remove(id);
  }

  removeOnDb(id: ID) {
    const options = getHttpOptionsWithInclude(CostCentersService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .delete<ApiResponse>(environment.api.baseUrl + 'cost-centers/' + id, options)
      .pipe(
        setLoading(this.costCentersStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.costCentersStore)),
        tap(() => {
          this.remove(id);
          this.snackBar.open('Kostenstelle wurde erfolgreich gelöscht!', 'Schließen', {panelClass: 'snackbar-success'});
        }),
      );
  }

  removeApproverFromCostCenterOnDb(id: number, approverId: number): Observable<ApiResponse<Approver>> {
    const options = getHttpOptionsWithInclude(ApproversService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .delete<ApiResponse<Approver>>(
        environment.api.baseUrl + 'cost-centers/' + id + '/approvers/' + approverId,
        options
      ).pipe(
        setLoading(this.costCentersStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.costCentersStore)),
        tap(() => {
          // Remove approver from approvers
          if (this.approversQuery.hasEntity(approverId)) {
            this.approversService.remove(approverId);
          }
        }),
      );
  }

  navigateToCostCenterDetail(id: number): Promise<boolean> {
    return this.router.navigate([
      ManagementRouteNames.MANAGEMENT + '/' + CostCenterRouteNames.COST_CENTER,
      id
    ]);
  }

  navigateToCostCenterCreate(): Promise<boolean> {
    return this.router.navigate([
      ManagementRouteNames.MANAGEMENT +
      '/' +
      CostCenterRouteNames.COST_CENTER +
      '/' +
      CostCenterRouteNames.CREATE
    ]);
  }

  navigateToCostCenterEdit(id: number): Promise<boolean> {
    return this.router.navigate([
      ManagementRouteNames.MANAGEMENT +
      '/' +
      CostCenterRouteNames.COST_CENTER +
      '/' +
      id +
      '/' +
      'edit'
    ]);
  }

  navigateToCostCenterList(): Promise<boolean> {
    return this.router.navigate([
      ManagementRouteNames.MANAGEMENT +
      '/' +
      CostCenterRouteNames.COST_CENTER +
      '/' +
      CostCenterRouteNames.LIST
    ]);
  }
}
