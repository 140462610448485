import {SupplierProduct} from '@/shop/supplier-product/state/supplier-product.model';
import {SupplierProductsService} from '@/shop/supplier-product/state/supplier-products.service';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-supplier-product-favorite-icon',
  templateUrl: './supplier-product-favorite-icon.component.html',
  styleUrls: ['./supplier-product-favorite-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierProductFavoriteIconComponent {
  @Input({required: true}) supplierProduct!: SupplierProduct;

  get isFavorite() {
    return this.supplierProduct.is_favorite;
  }

  get tooltip() {
    return this.isFavorite ? 'Von meinen Favoriten entfernen' : 'Zu meinen Favoriten hinzufügen';
  }

  get favoriteIcon() {
    return this.isFavorite ? 'favorite' : 'favorite_border';
  }

  constructor(private supplierProductsService: SupplierProductsService) {
  }

  toggleFavorite() {
    this.supplierProductsService.updateFavoriteStatusForSupplierProduct(this.supplierProduct.id, !this.isFavorite).subscribe();
  }
}
