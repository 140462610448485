import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {Observable} from 'rxjs';
import {TenantState, TenantStore} from './tenant.store';

@Injectable({providedIn: 'root'})
export class TenantQuery extends QueryEntity<TenantState> {
  activeTenant$ = this.selectActive();

  activeTenantId$ = this.selectActiveId();

  constructor(protected store: TenantStore) {
    super(store);
  }

  selectNameById$(id: number): Observable<string> {
    return this.selectEntity(id, ({name}) => name);
  }
}
