export enum OrderStatusName {
  new = 'new',
  finished = 'finished',
  rejected = 'rejected',
  approved = 'approved',
  canceled = 'canceled',
  inProgress = 'in-progress',
  waitingForApproval = 'waiting-for-approval',
  approverProductCategories = 'approver-product-categories',
  approverLevel1 = 'approver-level-1',
  approverLevel2 = 'approver-level-2'
}
