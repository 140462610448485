import {SharedModule as GlobalSharedModule} from '@/shared';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ProductUnitDisplayTextPipe} from '../shared/pipes/product-unit-display-text.pipe';
import {
  SupplierProductGalleryItemComponent
} from '../supplier-product/components/supplier-product-gallery-item/supplier-product-gallery-item.component';
import {
  SupplierProductGalleryComponent
} from '../supplier-product/components/supplier-product-gallery/supplier-product-gallery.component';
import {
  SupplierProductWithLowestBillingFrequencyPricePipe
} from '../supplier-product/pipes/supplier-product-with-lowest-billing-frequency-price.pipe';
import {
  SupplierProductWithLowestUnitPricePipe
} from '../supplier-product/pipes/supplier-product-with-lowest-unit-price.pipe';


@NgModule({
  imports: [
    CommonModule,
    GlobalSharedModule
  ],
  declarations: [
    SupplierProductGalleryComponent,
    SupplierProductGalleryItemComponent,
    ProductUnitDisplayTextPipe,
    SupplierProductWithLowestUnitPricePipe,
    SupplierProductWithLowestBillingFrequencyPricePipe,
  ],
  exports: [
    SupplierProductGalleryComponent,
    SupplierProductGalleryItemComponent,
    ProductUnitDisplayTextPipe,
  ],
})
export class SharedModule {
}
