import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { QuantityUnit } from './quantity-unit.model';

export interface QuantityUnitsState extends EntityState<QuantityUnit> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'quantityUnits' })
export class QuantityUnitsStore extends EntityStore<QuantityUnitsState> {

  constructor() {
    super({loading: false});
  }

}
