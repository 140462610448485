import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {OrderAvailableStatus} from './order-available-status.model';

export interface OrderAvailableStatusesState extends EntityState<OrderAvailableStatus> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'order-available-statuses'})
export class OrderAvailableStatusesStore extends EntityStore<OrderAvailableStatusesState> {
  constructor() {
    super({loading: false});
  }
}
