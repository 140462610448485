import {NavigationMode} from '../models';

export abstract class SharedConstants {
  static readonly NAVIGATION_MODES: NavigationMode[] = [
    {
      key: 'purchase',
      value: 'Einkaufen',
      module: 'shop'
    },
    {
      key: 'manage',
      value: 'Verwalten',
      module: 'management'
    },
    // {
    //   key: 'administrate',
    //   value: 'Einstellungen',
    //   model: 'settings',
    // },
  ];
}
