import {Supplier} from '@/management/supplier/state/supplier.model';
import {
  calculateMinimumOrderValueSurcharge,
  SupplierInformationForMinimumOrderValueSurcharge
} from '../directives/helpers/calculate-minimum-order-value-surcharge';
import {
  calculateShippingCostsForSupplier,
  SupplierInformationForShippingCosts
} from '../directives/helpers/calculate-shipping-costs-for-supplier';

export type SupplierInformationForTotalPriceForSupplier =
  SupplierInformationForShippingCosts &
  SupplierInformationForMinimumOrderValueSurcharge &
  Pick<
    Supplier,
    'toll_charge' |
    'additional_costs'
  >;

export const calculateTotalPriceForSupplier = (supplier: SupplierInformationForTotalPriceForSupplier, totalUnitPrice: number) => {
  if (!supplier) {
    return totalUnitPrice;
  }

  return totalUnitPrice
    + calculateShippingCostsForSupplier(supplier, totalUnitPrice)
    + +supplier.toll_charge
    + +calculateMinimumOrderValueSurcharge(supplier, totalUnitPrice)
    + +supplier.additional_costs;
}
