<div class="favorite-icon-container">
    <button
      class="favorite-icon"
      mat-mini-fab
      aria-label="Hinzufügen oder Entfernen von einem Produkt als Favorit"
      (click)="toggleFavorite()"
      [matTooltip]="tooltip">
        <mat-icon fontSet="material-icons-outlined">{{favoriteIcon}}</mat-icon>
    </button>
</div>
