import {DqnAutocompleteOption} from '@dqn/components-library/autocomplete';
import {OperatorFunction} from 'rxjs';
import {map} from 'rxjs/operators';
import {MicrosoftGraphUser} from '../microsoft-graph-user.model';

const mapGraphUsersToAutocompleteOptionsSync = (graphUsers: MicrosoftGraphUser[]) => graphUsers.map(graphUser => ({
  value: graphUser.id,
  title: graphUser.display_name,
  attributes: [
    graphUser.user_principal_name,
  ],
} as DqnAutocompleteOption));


export const mapGraphUsersToAutocompleteOptions = (): OperatorFunction<MicrosoftGraphUser[], DqnAutocompleteOption[]> => map(e => mapGraphUsersToAutocompleteOptionsSync(e));
