import {HomeService} from '@/home/state/home.service';
import {SharedConstants} from '@/shared/constants';
import {UiService} from '@/shared/state/ui.service';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {PermissionsService} from '../../state/permissions/permissions.service';
import {SessionService} from '../../state/session.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard {
  navigationModes = SharedConstants.NAVIGATION_MODES;

  constructor(
    private sessionService: SessionService,
    private uiService: UiService,
    private permissionsService: PermissionsService,
    private homeService: HomeService,
  ) {
  }

  async canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    let result;

    const permissions = route.data['permissions'];

    const module = route.data['module'] as string;
    const navigationModeByModule = this.navigationModes.find(navigationMode => navigationMode.module === module);
    if (permissions) {
      result = await this.permissionsService.checkIfUserHasOneOrMorePermissionTo(permissions);
      if (result) {
        this.uiService.updateNavigationMode(navigationModeByModule); // TODO Check frontend permission for module
      } else {
        this.homeService.navigateToHome();
      }
    } else {
      result = of(true).toPromise();
      this.uiService.updateNavigationMode(navigationModeByModule); // TODO Check frontend permission for module
    }

    return result;
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    let result;

    const permissions = route.data['permissions'];

    if (permissions) {
      result = await this.permissionsService.checkIfUserHasOneOrMorePermissionTo(permissions);
    } else {
      result = of(true).toPromise();
    }

    return result;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const permissions = route.data['permissions'];
    // return this.permissionsService.checkIfUserHasOneOrMorePermissionTo(permissions);
    return true;
  }
}
