import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Supplier} from './supplier.model';

export type SuppliersState = EntityState<Supplier> & ActiveState;

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'suppliers'})
export class SuppliersStore extends EntityStore<SuppliersState> {
  constructor() {
    super({loading: false});
  }
}
