import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {ProductCategoryApprover} from './product-category-approver.model';

export interface ProductCategoryApproversState extends EntityState<ProductCategoryApprover> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'product-category-approvers'})
export class ProductCategoryApproversStore extends EntityStore<ProductCategoryApproversState> {
    constructor() {
        super({loading: false});
    }
}
