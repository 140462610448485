/** Error when invalid control is dirty, touched, or submitted. */
import {ErrorStateMatcher} from '@angular/material/core';
import {FormGroupDirective, NgForm, UntypedFormControl} from '@angular/forms';

export class PropertyErrorStateMatcher implements ErrorStateMatcher {
  private readonly name: string;
  private readonly errors: [];

  constructor(name: string, errors: []) {
    this.name = name;
    this.errors = errors;
  }

  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    const hasServerErrors = this.errors && this.errors[this.name]?.length > 0;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted) || hasServerErrors);
  }
}
