import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HomeRouteNames} from '../constants';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  constructor(
    private router: Router
  ) {
  }

  navigateToHome() {
    this.router.navigateByUrl('/' + HomeRouteNames.HOME);
  }
}
