import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {OrderItem} from './order-item.model';

export interface OrderItemsState extends EntityState<OrderItem> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'order-items'})
export class OrderItemsStore extends EntityStore<OrderItemsState> {
  constructor() {
    super({loading: false});
  }
}
