import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {getHttpOptionsWithParams} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {ID, setLoading} from '@datorama/akita';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {SessionQuery} from '../session.query';
import {Permission} from './permission.model';
import {PermissionsQuery} from './permissions.query';
import {PermissionsStore} from './permissions.store';

@Injectable({providedIn: 'root'})
export class PermissionsService {
  private readonly permissionsStore = inject(PermissionsStore);
  private readonly permissionsQuery = inject(PermissionsQuery);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);
  private readonly snackBar = inject(MatSnackBar);

  get(userId: number) {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http
      .get<ApiResponse<Permission[]>>(environment.api.baseUrl + 'users/' + userId + '/frontend-permissions', options)
      .pipe(
        setLoading(this.permissionsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.permissionsStore)),
        tap((response) => {
          this.permissionsStore.set(response.data);
        })
      );
  }


  async checkIfUserHasOneOrMorePermissionTo(permissions: Partial<Permission>[]): Promise<boolean> {
    let hasOneOrMorePermission = this.permissionsQuery.getHasOneOrMorePermissionToInStore(permissions);
    if (!hasOneOrMorePermission) {
      // Call api to get permissions
      const userId = this.sessionQuery.userId;
      if (userId) {
        await this.get(userId).toPromise();
      }
      hasOneOrMorePermission = this.permissionsQuery.getHasOneOrMorePermissionToInStore(permissions);
    }

    return hasOneOrMorePermission;
  }

  add(permission: Permission) {
    this.permissionsStore.add(permission);
  }

  update(id, permission: Partial<Permission>) {
    this.permissionsStore.update(id, permission);
  }

  remove(id: ID) {
    this.permissionsStore.remove(id);
  }

  private hasOneOrMorePermissionToInStore(permissions: Partial<Permission>[]) {
    if (Array.isArray(permissions)) {
      for (const permission of permissions) {
        let allowedScopes = null;
        switch (permission?.scope) {
          case 'user':
            allowedScopes = ['*', 'tenant', 'user'];
            break;
          case 'tenant':
            allowedScopes = ['*', 'tenant'];
            break;
          case '*':
            allowedScopes = ['*'];
            break;
          default:
            allowedScopes = null;
        }

        let allowedActions = null;
        switch (permission?.action) {
          case 'create':
            allowedActions = ['*', 'create'];
            break;
          case 'read':
            allowedActions = ['*', 'read'];
            break;
          case 'update':
            allowedActions = ['*', 'update'];
            break;
          case 'delete':
            allowedActions = ['*', 'delete'];
            break;
          case '*':
            allowedActions = ['*'];
            break;
          case 'use':
            allowedActions = ['use'];
            break;
          default:
            allowedActions = null;
        }

        if (permission?.id && this.permissionsQuery.hasEntity(permission.id)) {
          return true;
        } else if (
          this.permissionsQuery.hasEntity(
            ({model, scope, attribute, action}) =>
              (!permission?.model || (model === (permission?.model ?? null)) || model === '*')
              && (!permission?.attribute || (!attribute || attribute === (permission?.attribute ?? null)))
              && (!permission?.scope || (allowedScopes?.includes(scope) ?? null))
              && (!permission?.action || (allowedActions?.includes(action) ?? null))
          )
        ) {
          return true;
        }
      }
    }

    return false;
  }
}
