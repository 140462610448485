import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {ApprovalWorkflowsState, ApprovalWorkflowsStore} from './approval-workflows.store';

@Injectable({providedIn: 'root'})
export class ApprovalWorkflowsQuery extends QueryEntity<ApprovalWorkflowsState> {
  isLoading$ = this.selectLoading();

  constructor(
    protected store: ApprovalWorkflowsStore,
  ) {
    super(store);
  }
}
