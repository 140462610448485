import {SessionQuery} from '@/core/session/state/session.query';
import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {createHttpIncludes, getHttpOptionsWithInclude} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {Router} from '@angular/router';
import {ID, setLoading} from '@datorama/akita';
import {catchError, tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {ManagementRouteNames} from '../../constants/routes.constants';
import {SupplierRouteNames} from '../constants';
import {Supplier} from './supplier.model';
import {SuppliersStore} from './suppliers.store';

@Injectable({providedIn: 'root'})
export class SuppliersService {
  static readonly includes = createHttpIncludes([
    'status',
    'createdBy'
  ]);

  private readonly suppliersStore = inject(SuppliersStore);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);
  private readonly router = inject(Router);
  private readonly snackBar = inject(MatSnackBar);

  get() {
    const options = getHttpOptionsWithInclude(SuppliersService.includes, {
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http.get<ApiResponse<Supplier[]>>(environment.api.baseUrl + 'suppliers', options)
      .pipe(
        setLoading(this.suppliersStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.suppliersStore)),
        tap((response) => {
          this.suppliersStore.set(response['data']);
        })
      );
  }

  getById(id: number) {
    const options = getHttpOptionsWithInclude(SuppliersService.includes, {
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http
      .get<ApiResponse<Supplier>>(environment.api.baseUrl + 'suppliers/' + id, options)
      .pipe(
        setLoading(this.suppliersStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.suppliersStore)),
        tap((response) => {
          this.suppliersStore.add(response['data']);
        }),
      );
  }

  add(supplier: Supplier) {
    this.suppliersStore.add(supplier);
  }

  addToDb(supplier: Supplier) {
    const options = getHttpOptionsWithInclude(SuppliersService.includes, {
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http
      .post<ApiResponse<Supplier>>(environment.api.baseUrl + 'suppliers', supplier, options)
      .pipe(
        setLoading(this.suppliersStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.suppliersStore)),
        tap((response) => {
          this.add(response['data']);
        }),
      );
  }

  addToDbWithoutRedirect(supplier: Supplier) {
    const options = getHttpOptionsWithInclude(SuppliersService.includes, {
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http.post<ApiResponse<Supplier>>(environment.api.baseUrl + 'suppliers', supplier, options).pipe(
      setLoading(this.suppliersStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.suppliersStore)),
      tap(({data: newSupplier}) => {
        this.add(newSupplier);
      }),
    );
  }

  update(id, supplier: Partial<Supplier>) {
    this.suppliersStore.update(id, supplier);
  }

  updateOnDb(supplier: Supplier) {
    const options = getHttpOptionsWithInclude(SuppliersService.includes, {
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http
      .put<ApiResponse<Supplier>>(environment.api.baseUrl + 'suppliers/' + supplier.id, supplier, options)
      .pipe(
        setLoading(this.suppliersStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.suppliersStore)),
        tap((response) => {
          this.suppliersStore.add(response['data']);
        }),
      );
  }

  remove(id: ID) {
    this.suppliersStore.remove(id);
  }

  navigateToSupplierDetail(id: number): Promise<boolean> {
    return this.router.navigate([
      ManagementRouteNames.MANAGEMENT + '/' + SupplierRouteNames.SUPPLIER,
      id,
    ]);
  }

  navigateToSupplierCreate(): Promise<boolean> {
    return this.router.navigate([
      ManagementRouteNames.MANAGEMENT +
      '/' +
      SupplierRouteNames.SUPPLIER +
      '/' +
      SupplierRouteNames.CREATE,
    ]);
  }

  navigateToSupplierEdit(id: number): Promise<boolean> {
    return this.router.navigate([
      ManagementRouteNames.MANAGEMENT +
      '/' +
      SupplierRouteNames.SUPPLIER +
      '/' +
      id +
      '/' +
      'edit',
    ]);
  }

  navigateToSupplierList(): Promise<boolean> {
    return this.router.navigate([
      ManagementRouteNames.MANAGEMENT +
      '/' +
      SupplierRouteNames.SUPPLIER +
      '/' +
      SupplierRouteNames.LIST,
    ]);
  }

  setActiveSupplier(id: Supplier['id']) {
    this.suppliersStore.setActive(id);
  }
}
