import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import {CostCenter} from './cost-center.model';

export interface CostCentersState extends EntityState<CostCenter> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cost-centers' })
export class CostCentersStore extends EntityStore<CostCentersState> {

  constructor() {
    super({loading: false});
  }

}
