import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {enableAkitaProdMode, persistState} from '@datorama/akita';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {sentryOptions} from './config/sentry';
import * as Sentry from '@sentry/angular-ivy';

if (environment.sentryActive) {
  Sentry.init(sentryOptions);
}

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

const storage = persistState({
  include: ['shoppingCart', 'ui'],
  preStorageUpdate(storeName, state) {
    return state;
  },
  preStoreUpdate(storeName, state) {
    return state;
  },
});
const providers = [{provide: 'persistStorage', useValue: storage}];

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
