import {FaqsService} from '@/shop/faq/state/faqs.service';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {UiQuery} from '../../state/ui.query';

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss'],
})
export class SubheaderComponent {
  @Input() isSidenavOpen: boolean;
  @Output() sideNavToggled = new EventEmitter<void>();
  isLoading$: Observable<boolean>;

  constructor(
    private faqService: FaqsService,
    private uiQuery: UiQuery
  ) {
    this.isLoading$ = this.uiQuery.isLoading$;
  }

  toggleSidebar(): void {
    this.sideNavToggled.emit();
  }

  navigateToFaq() {
    return this.faqService.navigateToFaq();
  }
}
