import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { OrderActivity } from './order-activity.model';

export interface OrderActivitiesState extends EntityState<OrderActivity> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'order-activities' })
export class OrderActivitiesStore extends EntityStore<OrderActivitiesState> {

  constructor() {
    super({loading: false});
  }

}
