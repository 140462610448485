import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig,} from '@datorama/akita';
import {ProductCategoryTenant} from './product-category-tenant.model';

export interface ProductCategoryTenantsState extends EntityState<ProductCategoryTenant>, ActiveState {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'productCategoryTenants'})
export class ProductCategoryTenantsStore extends EntityStore<ProductCategoryTenantsState> {
  constructor() {
    super({loading: false});
  }
}
