import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {BillingFrequenciesState, BillingFrequenciesStore} from './billing-frequencies.store';
import {BillingFrequency} from './billing-frequency.model';

@Injectable({providedIn: 'root'})
export class BillingFrequenciesQuery extends QueryEntity<BillingFrequenciesState> {
  billingFrequencies$ = this.selectAll();
  monthly$ = this.selectAll({
    filterBy: ({name}) => name === 'monthly'
  });

  constructor(protected store: BillingFrequenciesStore) {
    super(store);
  }

  selectBillingFrequencyById(id: BillingFrequency['id']) {
    return this.selectEntity(id);
  }
}
