import {MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';

export const getMatDialogConfig = <D = any>(config: Partial<MatDialogConfig<D>>) => {
  const dialogConfig = new MatDialogConfig<D>();

  for (const [key, value] of Object.entries(config)) {
    dialogConfig[key] = value;
  }

  return dialogConfig;
};
