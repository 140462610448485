import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {first, map} from 'rxjs/operators';
import {TenantImagesState, TenantImagesStore} from './tenant-images.store';

@Injectable({providedIn: 'root'})
export class TenantImagesQuery extends QueryEntity<TenantImagesState> {
  constructor(protected store: TenantImagesStore) {
    super(store);
  }

  getDefaultImageUrlByTenantId(id: number) {
    return this.selectAll({
      filterBy: [
        ({tenant_id}) => tenant_id === id,
        ({name}) => name === 'default'
      ]
    }).pipe(
      first(),
      map(x => (Array.isArray(x) && x.length) ? x[0]?.urls?.original : null) // Get first entity
    );
  }
}
