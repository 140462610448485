import {Supplier} from '@/management/supplier/state/supplier.model';
import {calculateBillingFrequencyPriceTotal} from '../../../shared/helpers/calculate-billing-frequency-price-total';
import {calculateUnitPriceTotal} from '../../../shared/helpers/calculate-unit-price-total';
import {OrderItem} from '../order-items/order-item.model';
import {OrderItemsForSupplier} from '../types/order-items-for-supplier';

export const groupOrderItemsBySuppliers = (orderItems: OrderItem[], suppliers: Supplier[]) => {
  const groupedBySuppliers = orderItems
    .map(orderItem => ({id: orderItem.supplier_id}))
    // Remove duplicates
    .filter((supplier, index, self) =>
      index === self.findIndex(orderItemSupplier => (orderItemSupplier.id === supplier.id))
    );
  const orderItemsGroupBySuppliers: OrderItemsForSupplier[] = [];

  for (const groupedSupplier of groupedBySuppliers) {
    const orderItemsOfSupplier = orderItems.filter((orderItem) => orderItem.supplier_id === groupedSupplier.id);
    const unitPrices = orderItemsOfSupplier.map(({unit_price, quantity}) => unit_price * quantity);
    const billingFrequencyPrices = orderItemsOfSupplier.map(
      ({billing_frequency_price, quantity}) => billing_frequency_price * quantity
    );

    orderItemsGroupBySuppliers.push({
      supplier_id: groupedSupplier.id,
      supplier_name: suppliers.find(supplier => supplier.id === groupedSupplier.id)?.name,
      unitPriceTotal: calculateUnitPriceTotal(unitPrices),
      billingFrequencyPriceTotal: calculateBillingFrequencyPriceTotal(billingFrequencyPrices),
      orderItems: orderItemsOfSupplier
    });
  }
  return orderItemsGroupBySuppliers;
};
