import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'priceToHighestCurrencyValue'
})
export class PriceToHighestCurrencyValuePipe implements PipeTransform {
  /**
   * Transforms price from lowest currency value to highest currency by dividing by 100.
   * For Example: cent to € or penny to £
   *
   * @param netPrice Net price
   * @param args
   */
  transform(price: number): number {
    return isNaN(price) ? null : price / 100;
  }
}
