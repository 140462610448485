import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { QuantityUnitsStore, QuantityUnitsState } from './quantity-units.store';

@Injectable({ providedIn: 'root' })
export class QuantityUnitsQuery extends QueryEntity<QuantityUnitsState> {

  constructor(protected store: QuantityUnitsStore) {
    super(store);
  }

  quantityUnits$ = this.selectAll();
}
