/* eslint-disable @typescript-eslint/naming-convention */
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import {environment} from '../environments/environment';
import {MsalGuardConfiguration, MsalInterceptorConfiguration} from '@azure/msal-angular';

const {auth, api} = environment;
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

const loggerCallback = (logLevel: LogLevel, message: string) => {
};

const msalPublicClientApplicationConfiguration = {
  auth: {
    clientId: auth.clientId,
    authority: auth.authority,
    redirectUri: auth.redirectUri,
    postLogoutRedirectUri: auth.postLogoutRedirectUri,
    cloudDiscoveryMetadata: auth.cloudDiscoveryMetadata ?? null,
    authorityMetadata: auth.authorityMetadata ?? null,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE, // set to true for IE 11
  },
  system: {
    loggerOptions: {
      loggerCallback,
      logLevel: LogLevel.Info,
      piiLoggingEnabled: false
    }
  }
};

export const MSALInstanceFactory = (): IPublicClientApplication => new PublicClientApplication(
  msalPublicClientApplicationConfiguration);

export const MSALInterceptorConfigFactory = (): MsalInterceptorConfiguration => {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(api.baseUrl, auth.consentScopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
};

export const MSALGuardConfigFactory = (): MsalGuardConfiguration => ({
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: auth.consentScopes
  }
});
