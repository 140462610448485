import {Tenant} from '@/core/session/state/tenant/tenant.model';
import {WithAllowanceConfig} from '@/shared/types/api/with-allowance-config';
import {WithBaseTimestamps} from '@/shared/types/api/with-timestamps';
import {ProductCategory} from '../product-categories/product-category.model';

export interface ProductCategoryTenant extends WithBaseTimestamps, WithAllowanceConfig {
  id: number;
  tenant_id: Tenant['id'];
  product_category_id: ProductCategory['id'];
  product_category?: ProductCategory;
}

export const createProductCategoryTenant = (params: Partial<ProductCategoryTenant>) => ({...params} as ProductCategoryTenant);
