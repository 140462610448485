import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {ProductAttributeGroup} from './product-attribute-group.model';

export type ProductAttributeGroupsState = EntityState<ProductAttributeGroup>;

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'productAttributeGroups'})
export class ProductAttributeGroupsStore extends EntityStore<ProductAttributeGroupsState> {

  constructor() {
    super({loading: false});
  }

}

