<div
  *ngIf="supplierProduct?.unit_price"
  [class.unit-price]="useStyling">
    {{ unitPrice | currency: supplierProduct.currency_code?.name }}

    <span
      *ngIf="canDisplayScalePrices"
      class="scale-price">({{ unitScalePrice | currency: supplierProduct.currency_code?.name }}
        / {{ product.basic_quantity | number: '1.0-2' }} {{ product.scale_unit?.display_name }})</span>
</div>
<div
  *ngIf="supplierProduct?.billing_frequency_price"
  [class.billing-frequency-price]="useStyling">
    {{ frequencyPrice| currency: supplierProduct?.billing_frequency_currency_code?.name }} {{ supplierProduct?.billing_frequency?.display_name }}

    <span
      *ngIf="canDisplayScalePrices"
      class="scale-price">({{ frequencyScalePrice | currency: supplierProduct.currency_code?.name }}
        / {{ product.basic_quantity | number: '1.0-2' }} {{ product.scale_unit?.display_name }})</span>
</div>
