<ng-container *ngIf="childProductCategories.length > 0">
    <ng-container *ngFor="let childProductCategory of childProductCategories">
        <ng-container *ngIf="childProductCategory.children.length > 0; else noChildren">
            <div [className]="'productCategoryMain productCategoryMain-' + indentation">
                <mat-expansion-panel>
                    <mat-expansion-panel-header
                      (click)="navigateToProductCategoryDetails(childProductCategory.id)">
                        <mat-panel-title>
                            <div
                              class="flex justify-center items-center">
                                <span>{{ childProductCategory.display_name }}</span>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-sidenav-category-tree
                      [childProductCategories]="childProductCategory.id | childCategoriesByParentId | async"
                      [indentation]="indentation+1">
                    </app-sidenav-category-tree>
                </mat-expansion-panel>
            </div>
        </ng-container>
        <ng-template #noChildren>
            <div
              [className]="'tree-link-wrapper tree-link-wrapper-' + indentation"
              (click)="navigateToProductCategoryDetails(childProductCategory.id)">
                <a>{{ childProductCategory.display_name }}</a>
            </div>
        </ng-template>
    </ng-container>
</ng-container>
