import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {OrderStatusesState, OrderStatusesStore} from './order-statuses.store';

@Injectable({providedIn: 'root'})
export class OrderStatusesQuery extends QueryEntity<OrderStatusesState> {
  orderStatuses$ = this.selectAll();

  get orderStatuses() {
    return this.getAll();
  }

  constructor(
    protected store: OrderStatusesStore,
  ) {
    super(store);
  }

  getOrderStatusByName(name: string) {
    return this.orderStatuses.find(orderStatus => orderStatus.name === name)?.id;
  }
}
