import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {OrderTasksState, OrderTasksStore} from './order-tasks.store';

@Injectable({providedIn: 'root'})
export class OrderTasksQuery extends QueryEntity<OrderTasksState> {
  orderTasks$ = this.selectAll();

  constructor(protected store: OrderTasksStore) {
    super(store);
  }
}
