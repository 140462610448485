import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CurrencyCode } from './currency-code.model';

export type CurrencyCodesState = EntityState<CurrencyCode>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'currencyCodes' })
export class CurrencyCodesStore extends EntityStore<CurrencyCodesState> {

  constructor() {
    super({loading: false});
  }

}
