import {ProductCategoriesQuery} from '@/shop/supplier-product/state/product-categories/product-categories.query';
import {ProductCategory} from '@/shop/supplier-product/state/product-categories/product-category.model';
import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';

@Pipe({
  name: 'childCategoriesByParentId'
})
export class ChildCategoriesByParentIdPipe implements PipeTransform {

  constructor(
    private productCategoriesQuery: ProductCategoriesQuery
  ) {
  }

  transform(productCategoryId: number): Observable<ProductCategory[]> {
    return this.productCategoriesQuery.selectChildCategoriesOfParent(productCategoryId);
  }

}
