import {Injectable} from '@angular/core';
import {ID} from '@datorama/akita';
import {createHttpIncludes} from '../../utils/functions/http-params';
import {ProductCategoryApprover} from './product-category-approver.model';
import {ProductCategoryApproversStore} from './product-category-approvers.store';

@Injectable({providedIn: 'root'})
export class ProductCategoryApproversService {
  static readonly includes = createHttpIncludes([
    'user',
    'tenant',
  ]);

  constructor(private productCategoryApproversStore: ProductCategoryApproversStore) {
  }

  add(productCategoryApprover: ProductCategoryApprover) {
    this.productCategoryApproversStore.add(productCategoryApprover);
  }

  update(id, productCategoryApprover: Partial<ProductCategoryApprover>) {
    this.productCategoryApproversStore.update(id, productCategoryApprover);
  }

  upsert(id, productCategoryApprover: Partial<ProductCategoryApprover>) {
    this.productCategoryApproversStore.upsert(id, productCategoryApprover);
  }

  remove(id: ID) {
    this.productCategoryApproversStore.remove(id);
  }
}
