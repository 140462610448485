import {Injectable} from '@angular/core';
import {ProductCategory} from './product-category.model';
import {ActiveState, EntityState, EntityStore, StoreConfig,} from '@datorama/akita';

export interface ProductCategoriesState extends EntityState<ProductCategory>, ActiveState {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'productCategories'})
export class ProductCategoriesStore extends EntityStore<ProductCategoriesState> {
  constructor() {
    super({loading: false});
  }
}
