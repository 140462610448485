import {faCrown as faCrownSolid} from '@fortawesome/pro-solid-svg-icons';
import {faCircleInfo as faCircleInfoRegular} from '@fortawesome/pro-regular-svg-icons';
import {IconDefinition} from '@fortawesome/pro-duotone-svg-icons';

export const getFontawesomeIcons = () => [
  // Solid
  faCrownSolid,
  // Regular
  faCircleInfoRegular,
] as IconDefinition[];
