import {Injectable} from '@angular/core';
import {SupplierProduct} from './supplier-product.model';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';

export interface SupplierProductsState extends EntityState<SupplierProduct>, ActiveState {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'supplierProducts'})
export class SupplierProductsStore extends EntityStore<SupplierProductsState> {

  constructor() {
    super({loading: false});
  }

}

