export interface WithParentId {
  id: string | number;
  parent_id: string | number;
}

export const collectBreadcrumbsViaParentIds = <T extends WithParentId>(entryPoint: T, collection: T[]) => {
  let collectedBreadCrumbs = [entryPoint];
  let lastBreadCrumb = entryPoint;

  while (lastBreadCrumb.parent_id) {
    const breadCrumb = collection.find(({id}) => id === lastBreadCrumb.parent_id);

    if (!breadCrumb) {
      break;
    }

    collectedBreadCrumbs = [breadCrumb, ...collectedBreadCrumbs];
    lastBreadCrumb = breadCrumb;
  }

  return collectedBreadCrumbs;
}
