import { Injectable } from '@angular/core';
import { SupplierProductAvailableStatus } from './supplier-product-available-status.model';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';

export interface SupplierProductAvailableStatusesState extends EntityState<SupplierProductAvailableStatus>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'supplierProductAvailableStatuses' })
export class SupplierProductAvailableStatusesStore extends EntityStore<SupplierProductAvailableStatusesState> {

  constructor() {
    super({loading: false});
  }

}

