import {Injectable} from '@angular/core';
import {ProductAttributeTemplate} from './product-attribute-template.model';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';

export type ProductAttributeTemplatesState = EntityState<ProductAttributeTemplate>;

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'productAttributeTemplates'})
export class ProductAttributeTemplatesStore extends EntityStore<ProductAttributeTemplatesState> {

  constructor() {
    super({loading: false});
  }

}

