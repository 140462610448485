import {environment} from '../../../../environments/environment';

/**
 * Get IP address and use fallback of '000.000.000.000' if ip can't be fetched.
 * IP address will be obfuscated by default (e.g. 192.168.1.255 instead of 192.168.178.20).
 */
export const getIpAddress = async (obfuscated = true): Promise<string> => {
  const fallBackIpAddress = '000.000.000.000';

  try {
    const ipUrl = environment.api.baseUrl.substr(0, environment.api.baseUrl.length - 3) + 'my-ip-address';
    const response = await fetch(ipUrl);
    const {data} = await response.json();

    if (data && data.ip) {
      // Replace last octet with '255' to obfuscate it if 'obfuscate' is set to true
      return obfuscated ? data.ip.substr(0, data.ip.lastIndexOf('.')) + '.255' : data.ip;
    }
  } catch (e) {
    return fallBackIpAddress;
  }

  return fallBackIpAddress;
};
