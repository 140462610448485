import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Permission } from './permission.model';

export interface PermissionsState extends EntityState<Permission> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'permissions' })
export class PermissionsStore extends EntityStore<PermissionsState> {

  constructor() {
    super({loading: false});
  }

}
