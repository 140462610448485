import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SupplierProduct} from '../../state/supplier-product.model';

@Component({
  selector: 'app-supplier-product-gallery',
  templateUrl: './supplier-product-gallery.component.html',
  styleUrls: ['./supplier-product-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierProductGalleryComponent {
  @Input() supplierProducts!: SupplierProduct[];

  trackBySupplierProductId(index: number, supplierProduct: SupplierProduct) {
    return supplierProduct.id;
  }
}
