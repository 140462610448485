<div
  class="gallery-item">
    <div class="flex gap-2">
        <div class="w-[50%] supplier-product-image-container">
            <div class="supplier-product-image">
                <button
                  *ngIf="rank"
                  mat-mini-fab
                  class="supplier-product-rank-icon">
                    #{{ rank }}
                </button>

                <app-supplier-product-favorite-icon
                  [supplierProduct]="supplierProduct"
                  class="supplier-product-favorite-icon">
                </app-supplier-product-favorite-icon>

                <img
                  [src]="supplierProduct?.product?.images | productDefaultImageUrl"
                  (click)="supplierProductsService.navigateToShopSupplierProductDetail(supplierProduct?.id)"
                  alt="Bild des Produktes"/>
            </div>
        </div>

        <div
          class="w-[50%] flex flex-col gap-2"
          (click)="supplierProductsService.navigateToShopSupplierProductDetail(supplierProduct?.id)">
            <div>
                <div>
                    {{ supplierProduct?.product?.name }}
                </div>
                <div class="eco">
                    <ng-container *ngFor="let i of [1, 2, 3, 4]">
                        <mat-icon fontSet="material-icons-round">eco</mat-icon>
                    </ng-container>
                    <ng-container *ngFor="let i of [1]">
                        <mat-icon fontSet="material-icons-outlined">eco</mat-icon>
                    </ng-container>
                </div>
            </div>

            <div *ngIf="supplierProductsWithSameBaseProduct$ | async as supplierProducts">
                <div
                  *ngIf="supplierProducts | supplierProductWithLowestUnitPrice as cheapestProduct"
                  class="unit-price">
                    <ng-container *ngIf="supplierProducts.length > 1">ab</ng-container>
                    {{
                    cheapestProduct?.unit_price | priceToHighestCurrencyValue | currency: cheapestProduct.currency_code?.name
                    }}
                </div>

                <div
                  *ngIf="supplierProducts | supplierProductWithLowestBillingFrequencyPrice as cheapestProduct"
                  class="billing-frequency-price">
                    <ng-container *ngIf="supplierProducts.length > 1">ab</ng-container>
                    {{
                    cheapestProduct?.billing_frequency_price | priceToHighestCurrencyValue
                      | currency: cheapestProduct?.billing_frequency_currency_code?.name
                    }} {{ cheapestProduct?.billing_frequency?.display_name }}
                </div>
            </div>

            <div *ngIf="environment.shopActive">
                <button
                  type="submit"
                  mat-raised-button
                  color="primary"
                  (click)="toShoppingCart($event)">
                    <mat-icon>shopping_cart</mat-icon>
                    In den Einkaufswagen
                </button>
            </div>
        </div>
    </div>
</div>
