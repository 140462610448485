import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Tenant} from './tenant.model';

export interface TenantState extends EntityState<Tenant>, ActiveState {

}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'tenant'})
export class TenantStore extends EntityStore<TenantState> {
  constructor() {
    super({loading: false});
  }
}
