import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ApprovalWorkflow } from './approval-workflow.model';

export interface ApprovalWorkflowsState extends EntityState<ApprovalWorkflow> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'approvalWorkflows' })
export class ApprovalWorkflowsStore extends EntityStore<ApprovalWorkflowsState> {

  constructor() {
    super({loading: false});
  }

}
