import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Approver } from './approver.model';

export interface ApproversState extends EntityState<Approver> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'approvers' })
export class ApproversStore extends EntityStore<ApproversState> {

  constructor() {
    super({loading: false});
  }

}
