import {SessionQuery} from '@/core/session/state/session.query';
import {SuppliersQuery} from '@/management/supplier/state/suppliers.query';
import {Injectable} from '@angular/core';
import {combineQueries, filterNilValue, QueryEntity} from '@datorama/akita';
import {distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {createOrder, Order} from '../../order/state/order.model';
import {calculateTotalPriceForSupplier} from '../../shared/helpers/calculate-total-price-for-supplier';
import {QuantityUnitsQuery} from '../../supplier-product/state/quantity-units/quantity-units.query';
import {groupOrderItemsBySuppliers} from './helpers/group-order-items-by-suppliers';
import {OrderTask} from './order-tasks/order-task.model';
import {OrderTasksQuery} from './order-tasks/order-tasks.query';
import {OrdersState, OrdersStore} from './orders.store';

@Injectable({providedIn: 'root'})
export class OrdersQuery extends QueryEntity<OrdersState> {
  isLoading$ = this.selectLoading();
  orders$ = this.selectAll();

  ordersForUser$ = combineQueries([
    this.orders$,
    this.sessionQuery.user$,
  ]).pipe(
    map(([orders, user]) => orders.filter(order => order.created_by_id === user?.id))
  );

  activeOrderId$ = this.selectActiveId();

  /** Treat the union of order and order task as one for outside usage as order tasks are still orders. */
  private activeOrderOrOrderTask$ = combineQueries([
    this.activeOrderId$.pipe(
      filterNilValue(),
      distinctUntilChanged(),
      switchMap(activeOrderId => this.selectOrderOrOrderTask(activeOrderId)),
    ),
    this.quantityUnitsQuery.quantityUnits$,
  ]).pipe(
    // Append quantity units to order items
    map(([order, quantityUnits]) => createOrder({
      ...order,
      order_items: order?.order_items.map(orderItem => ({
        ...orderItem,
        quantity_unit: quantityUnits?.find(({id}) => id === orderItem.quantity_unit_id),
        scale_unit: quantityUnits?.find(({id}) => id === orderItem.scale_unit_id),
      })),
    })),
  );
  /** Alias for order or order task to have a simpler public facing usage.*/
  activeOrder$ = this.activeOrderOrOrderTask$;

  activeOrderOrderItemsBySuppliers$ = combineQueries([
    this.activeOrder$,
    this.suppliersQuery.suppliers$,
  ]).pipe(
    filter(([, suppliers]) => suppliers?.length > 0),
    map(([order, suppliers]) => groupOrderItemsBySuppliers(order.order_items, suppliers)),
  );

  activeOrderPriceTotal$ = combineQueries([
    this.activeOrderOrderItemsBySuppliers$,
    this.activeOrder$,
  ]).pipe(
    map(([orderItemsBySuppliers, order]) => {
      return orderItemsBySuppliers.reduce((total, orderItemsBySupplier) => {
        const orderSupplierHistoryForItem = order.order_supplier_histories?.find(
          orderSupplierHistory => orderSupplierHistory.supplier_id === orderItemsBySupplier.supplier_id
        );
        return total + calculateTotalPriceForSupplier(orderSupplierHistoryForItem, orderItemsBySupplier.unitPriceTotal);
      }, 0)
    }),
  );

  activeOrderBillingFrequencyPriceTotal$ = this.activeOrder$.pipe(
    map(order => {
      let orderFrequencyPriceTotal = 0;
      if (order?.order_items?.length > 0) {
        for (const orderItem of order.order_items) {
          orderFrequencyPriceTotal += orderItem.quantity * orderItem.offer_billing_frequency_price;
        }
      }
      return orderFrequencyPriceTotal;
    })
  );

  constructor(
    protected store: OrdersStore,
    private sessionQuery: SessionQuery,
    private orderTasksQuery: OrderTasksQuery,
    private quantityUnitsQuery: QuantityUnitsQuery,
    private suppliersQuery: SuppliersQuery,
  ) {
    super(store);
  }

  /** Check if order is in orders store or in orderTasks store. If it is in both then take it from orders store. */
  selectOrderOrOrderTask(orderId: Order['id'] | OrderTask['id']) {
    return combineQueries([
      this.selectEntity(orderId),
      this.orderTasksQuery.selectEntity(orderId)
    ]).pipe(
      map(([order, orderTask]) => order ?? orderTask),
    );
  }
}
