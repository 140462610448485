import {
  collectBreadcrumbsViaParentIds
} from '@/shared/components/bread-crumbs/helpers/collect-breadcrumbs-via-parent-ids';
import {createBreadCrumb} from '@/shared/components/bread-crumbs/types/bread-crumb';
import {inject, Injectable} from '@angular/core';
import {combineQueries, filterNilValue, QueryEntity} from '@datorama/akita';
import {Observable} from 'rxjs';
import {map, startWith, switchMap} from 'rxjs/operators';
import {ShopRouteNames} from '../../../constants/routes.constants';
import {ProductCategoryRouteNames} from '../../../product-category/constants';
import {ProductCategoryTenantsQuery} from '../product-category-tenants/product-category-tenants.query';
import {ProductCategoriesState, ProductCategoriesStore,} from './product-categories.store';
import {ProductCategory} from './product-category.model';

@Injectable({providedIn: 'root'})
export class ProductCategoriesQuery extends QueryEntity<ProductCategoriesState> {
  private readonly productCategoryTenantsQuery = inject(ProductCategoryTenantsQuery);

  activeProductCategory$ = this.selectActive();

  /** @deprecated Using {@link productCategoriesForTenant$} should be preferred to only include categories tenant is allowed to see. */
  productCategories$ = this.selectAll();

  productCategoriesForTenant$ = combineQueries([
    this.productCategories$,
    this.productCategoryTenantsQuery.productCategoryTenantProductCategoryIdsForTenant$,
  ]).pipe(
    map(([productCategories, productCategoryTenantIds]) => {
      return productCategories.filter(({id}) => productCategoryTenantIds.includes(id));
    }),
  );

  mainProductCategories$ = this.productCategoriesForTenant$.pipe(
    map(productCategories =>
      productCategories.filter(({parent_id}) => parent_id === null)
    ),
  );

  breadCrumbsForActiveProductCategory$ =
    this.activeProductCategory$.pipe(
      filterNilValue(),
      switchMap(productCategory => this.selectBreadCrumbsForProductCategory(productCategory)),
    );

  constructor(protected store: ProductCategoriesStore) {
    super(store);
  }

  selectBreadCrumbsForProductCategoryById(productCategoryId: ProductCategory['id']) {
    return this.selectCategoryById(productCategoryId).pipe(
      filterNilValue(),
      switchMap(productCategory => this.selectBreadCrumbsForProductCategory(productCategory)),
    );
  }

  selectBreadCrumbsForProductCategory(productCategory: ProductCategory) {
    return this.productCategoriesForTenant$.pipe(
      map(productCategories => {
        return collectBreadcrumbsViaParentIds(productCategory, productCategories)
          .map(({id, display_name}) => createBreadCrumb({
            url: ['/', ShopRouteNames.SHOP, ProductCategoryRouteNames.PRODUCT_CATEGORY, id],
            label: display_name,
          }));
      }),
    );
  }

  selectChildCategoriesOfParent(parentCategoryId: number): Observable<ProductCategory[]> {
    return this.productCategoriesForTenant$.pipe(
      startWith([] as ProductCategory[]),
      map(productCategories =>
        productCategories.filter(productCategory => productCategory.parent_id === parentCategoryId)
      ),
    );
  }

  selectHasChildCategoriesOfParent(parentCategoryId: number) {
    return this.selectChildCategoriesOfParent(parentCategoryId).pipe(
      map(childCategories => childCategories.length > 0),
    );
  }

  selectCategoryById(id: number) {
    return this.selectEntity(id);
  }
}
