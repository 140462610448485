import { Injectable } from '@angular/core';
import { CountryCode } from './country-code.model';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

export interface CountryCodesState
  extends EntityState<CountryCode>,
    ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'countryCodes' })
export class CountryCodesStore extends EntityStore<CountryCodesState> {
  constructor() {
    super({loading: false});
  }
}
