import {Injectable} from '@angular/core';
import {filterNilValue, Query} from '@datorama/akita';
import {of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SessionState, SessionStore} from './session.store';
import {TenantSettingNames} from './tenant-settings/enums/tenant-setting-names';
import {getTenantSettingFromTenant} from './tenant-settings/helpers/get-tenant-setting-from-tenant';
import {TenantQuery} from './tenant/tenant.query';

@Injectable({providedIn: 'root'})
export class SessionQuery extends Query<SessionState> {
  user$ = this.select('user');

  userTenant$ = this.user$.pipe(map(user => user?.tenant));

  tenant$ = this.tenantQuery.activeTenant$.pipe(
    switchMap(activeTenant => {
      if (activeTenant) {
        return of(activeTenant);
      }
      return this.userTenant$;
    })
  );
  tenantName$ = this.tenant$.pipe(
    filterNilValue(),
    map(tenant => tenant?.name)
  );
  tenantOrdersApprovalWorkflow$ = this.tenant$.pipe(
    filterNilValue(),
    map(tenant => tenant?.orders_approval_workflow),
  );
  tenantSupportContactMail$ = this.tenant$.pipe(
    filterNilValue(),
    map(tenant => getTenantSettingFromTenant(tenant, TenantSettingNames.SupportContactMail)),
  );

  constructor(
    protected store: SessionStore,
    private tenantQuery: TenantQuery,
  ) {
    super(store);
  }

  get userEmail() {
    return this.getValue().user?.email;
  }

  get userId() {
    return this.getValue().user?.id;
  }

  get userName() {
    return this.getValue().user?.name;
  }

  get userObjectId() {
    return this.getValue().user?.object_id;
  }

  get userTenantId() {
    return this.getValue().user?.tenant_id;
  }

  get userTenantNavigationUrl() {
    return this.getValue().user?.tenant?.navigation_url;
  }

  get tenantId() {
    return this.getValue().user?.tenant_id;
  }

  get tenant() {
    return this.tenantQuery.getEntity(this.tenantId);
  }
}
