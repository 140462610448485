import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {User} from './user/user.model';

export interface SessionState {
  accessToken: string;
  accessTokenExpiresAt: Date;
  refreshToken: string;
  user: User;
  pendingUserRequest: boolean
}

export function createInitialState(): SessionState {
  return {
    accessToken: null,
    accessTokenExpiresAt: null,
    refreshToken: null,
    user: null,
    pendingUserRequest: false
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'session',
  cache: {
    ttl: 900000 // 15 minutes
  }
})
export class SessionStore extends Store<SessionState> {
  constructor() {
    super(createInitialState());
  }
}
