import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { LanguageCodesStore, LanguageCodesState } from './language-codes.store';

@Injectable({ providedIn: 'root' })
export class LanguageCodesQuery extends QueryEntity<LanguageCodesState> {
  constructor(protected store: LanguageCodesStore) {
    super(store);
  }
  languageCodes$ = this.selectAll();
}
