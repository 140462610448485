import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {ShoppingCartService} from '../../../shopping-cart/state/shopping-cart.service';
import {Product, SupplierProduct} from '../../state/supplier-product.model';

@Component({
  selector: 'app-supplier-products-product-list',
  templateUrl: './supplier-products-product-list.component.html',
  styleUrls: ['./supplier-products-product-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierProductsProductListComponent implements OnInit {
  private readonly shoppingCartService = inject(ShoppingCartService);

  @Input({required: true}) supplierProductsWithSameBaseProduct: SupplierProduct[];
  @Input() selectedSupplierProduct: SupplierProduct;
  @Input() quantity: number = 1;
  @Input() shopActive = true;

  @Output() onAddToShoppingCart = new EventEmitter<SupplierProduct>();
  @Output() onNavigateToSupplierProduct = new EventEmitter<SupplierProduct>();

  displayedColumns: string[] = ['attribute', 'value'];
  displayedColumnsForSupplierProductsWithSameBaseProduct: string[] = ['image', 'name', 'supplier', 'rating', 'price', 'addToBasket', 'goToProduct'];
  product: Product;

  ngOnInit() {
    this.product = this.supplierProductsWithSameBaseProduct.find(supplierProduct => supplierProduct?.product)?.product;

    if (!this.shopActive) {
      // Hide 'addToBasket' if shop is not active
      this.displayedColumnsForSupplierProductsWithSameBaseProduct = this.displayedColumnsForSupplierProductsWithSameBaseProduct.filter(
        column => {
          return column !== 'addToBasket';
        }
      );
    }
  }

  addToShoppingCart(supplierProductId: number = null) {
    this.shoppingCartService.addProductToCart(supplierProductId ?? this.selectedSupplierProduct.id, this.quantity);

    this.onAddToShoppingCart.emit(this.supplierProductsWithSameBaseProduct.find(supplierProduct => {
      return supplierProduct.id === supplierProductId;
    }));
  }

  navigateToSupplierProduct(supplierProduct: SupplierProduct) {
    this.onNavigateToSupplierProduct.emit(supplierProduct);
  }
}
