import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {OrderStatus} from './order-status.model';

export interface OrderStatusesState extends EntityState<OrderStatus> {
}

export const createInitialState = () => ({
  loading: false,
});

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'order-statuses'})
export class OrderStatusesStore extends EntityStore<OrderStatusesState> {

  constructor() {
    super(createInitialState());
  }
}
