import {Injectable} from '@angular/core';
import {SupplierProduct} from './supplier-product.model';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';

export interface SupplierProductsState extends EntityState<SupplierProduct>, ActiveState {
  ui: {
    searchTerm: string;
  };
}

export const createInitialUiState = (): SupplierProductsState['ui'] => ({
  searchTerm: '',
});

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'supplierProducts'})
export class SupplierProductsStore extends EntityStore<SupplierProductsState> {

  constructor() {
    super({loading: false, ui: createInitialUiState()});
  }
}

