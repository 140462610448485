import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface UiShopState {
   key: string;
}

export function createInitialState(): UiShopState {
  return {
    key: ''
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ui-shop' })
export class UiShopStore extends Store<UiShopState> {

  constructor() {
    super(createInitialState());
  }

}
