import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {
  SupplierProductAvailableStatusesState,
  SupplierProductAvailableStatusesStore
} from './supplier-product-available-statuses.store';

@Injectable({providedIn: 'root'})
export class SupplierProductAvailableStatusesQuery extends QueryEntity<SupplierProductAvailableStatusesState> {
  supplierAvailableStatuses$ = this.selectAll();

  constructor(protected store: SupplierProductAvailableStatusesStore) {
    super(store);
  }

  getStatusIdByName(name: string) {
    return this.getAll()
      .filter((availableStatus) => availableStatus?.status?.name === name)
      .map((availableStatus) => availableStatus?.status?.id).pop();
  }
}
