import {HttpParams} from '@angular/common/http';

export const createHttpIncludes = (includes: string[]) => includes.join(',');

export const createHttpAttributes = (attributes: string[]) => attributes.join(',');

type ParamMap = { [param: string]: string | number | boolean }

export const getHttpParams = (paramMap: ParamMap) => {
  let httpParams = new HttpParams();

  for (const [param, value] of Object.entries(paramMap)) {
    httpParams = httpParams.set(param, value);
  }

  return httpParams;
}

export const getHttpOptionsWithParams = (paramMap: ParamMap) => {
  const params = getHttpParams(paramMap);

  return {params};
};

export const getHttpOptionsWithInclude = (
  includes: string,
  additionalParameters: { [key: string]: unknown } = {}
) => getHttpOptionsWithParams({include: includes, ...additionalParameters});

export const getHttpOptionsWithIncludeAndAttributes = (
  includes: string,
  attributes: string,
  additionalParameters: { [key: string]: unknown } = {}
) => getHttpOptionsWithParams({
  include: includes,
  attributes,
  ...additionalParameters,
});
