import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig,} from '@datorama/akita';
import {Status} from '../../state/status/status.model';

export interface StatusesState
  extends EntityState<Status>,
    ActiveState {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'statuses'})
export class StatusesStore extends EntityStore<StatusesState> {
  constructor() {
    super({loading: false});
  }
}
