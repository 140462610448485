import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {SupplierProductImage} from './supplier-product-image.model';

export interface SupplierProductImagesState extends EntityState<SupplierProductImage> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'supplier-product-images', idKey: 'supplier_product_id'})
export class SupplierProductImagesStore extends EntityStore<SupplierProductImagesState> {

  constructor() {
    super({loading: false});
  }
}
