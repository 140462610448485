import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {NavigationMode} from '../models';

export interface UiState {
  isSidenavOpen: boolean;
  navigationMode: NavigationMode;
}

export const createInitialState = (): UiState => ({
  isSidenavOpen: true,
  navigationMode: {
    // TODO Get active navigation mode from API
    key: 'purchase',
    value: 'Einkaufen',
  },
});

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'ui'})
export class UiStore extends Store<UiState> {
  constructor() {
    super(createInitialState());
  }
}
