import {Supplier} from '@/management/supplier/state/supplier.model';

export type SupplierInformationForShippingCosts = Pick<
  Supplier,
  'shipping_costs' |
  'shipping_costs_minimum_order_value'
>;

export const calculateShippingCostsForSupplier = (supplier: SupplierInformationForShippingCosts, unitPriceTotal: number) => {
  const {shipping_costs, shipping_costs_minimum_order_value} = supplier;

  if (shipping_costs_minimum_order_value && unitPriceTotal >= shipping_costs_minimum_order_value) {
    return 0;
  }

  return shipping_costs;
}
