import {Tenant} from '../../tenant/tenant.model';
import {TenantSettings} from '../tenant-settings.model';

export const getTenantSettingFromTenant = (tenant: Tenant, tenantSettingName: TenantSettings['name']) => {
  if (!tenant) {
    return '';
  }

  return tenant.tenant_settings?.find(tenantSetting => tenantSetting.name === tenantSettingName)?.value ?? '';
}
