export const getDefaultImageUrl = (images: any) => {
  let imageUrl = './assets/default-placeholder.jpg';

  if (images) {
    for (const image of images) {
      if (image.name === 'default') {
        imageUrl = image.urls.original;
      }
    }
  }

  return imageUrl;
};
