import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {MicrosoftGraphUser} from './microsoft-graph-user.model';

export interface MicrosoftGraphUsersState extends EntityState<MicrosoftGraphUser> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'microsoftGraphUsers'})
export class MicrosoftGraphUsersStore extends EntityStore<MicrosoftGraphUsersState> {
  constructor() {
    super({loading: false});
  }
}
