import {SessionQuery} from '@/core/session/state/session.query';
import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {createHttpIncludes, getHttpOptionsWithInclude} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {Router} from '@angular/router';
import {setLoading} from '@datorama/akita';
import {catchError, take, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {ShopRouteNames} from '../../../constants/routes.constants';
import {ProductCategoryRouteNames} from '../../../product-category/constants';
import {ProductCategoriesQuery} from './product-categories.query';
import {ProductCategoriesStore} from './product-categories.store';
import {ProductCategory} from './product-category.model';

@Injectable({providedIn: 'root'})
export class ProductCategoriesService {
  static includes = createHttpIncludes([
    'children',
    'parent',
    'productsCount'
  ]);

  private readonly productCategoriesQuery = inject(ProductCategoriesQuery);
  private readonly productCategoriesStore = inject(ProductCategoriesStore);
  private readonly sessionService = inject(SessionQuery);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);
  private readonly router = inject(Router);
  private readonly snackBar = inject(MatSnackBar);

  get() {
    const options = getHttpOptionsWithInclude(ProductCategoriesService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .get<ApiResponse<ProductCategory[]>>(environment.api.baseUrl + 'product-categories', options)
      .pipe(
        setLoading(this.productCategoriesStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productCategoriesStore)),
        tap((response) => {
          this.productCategoriesStore.set(response['data']);
        }),
      );
  }

  getById(id: number) {
    const options = getHttpOptionsWithInclude(ProductCategoriesService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .get<ApiResponse<ProductCategory>>(environment.api.baseUrl + 'product-categories/' + id, options)
      .pipe(
        setLoading(this.productCategoriesStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productCategoriesStore)),
        tap((response) => {
          this.productCategoriesStore.add(response['data']);
        }),
      );
  }

  navigateToProductCategoryDetails(id: number): Promise<boolean> {
    if (id != this.productCategoriesQuery.getActiveId()) {
      this.setActiveProductCategory(null);
    }
    return this.router.navigate([
      ShopRouteNames.SHOP + '/' + ProductCategoryRouteNames.PRODUCT_CATEGORY,
      id
    ]);
  }

  setActiveProductCategory(id: number): void {
    if (!this.productCategoriesQuery.hasEntity(id)) {
      this.getById(id).pipe(take(1)).subscribe({
        next: (response) => {
          this.productCategoriesStore.setActive(response['data']?.id);
        }
      });
    } else {
      this.productCategoriesStore.setActive(id);
    }
  }
}
