import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface UiManagementState {
   key: string;
}

export function createInitialState(): UiManagementState {
  return {
    key: ''
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ui-management' })
export class UiManagementStore extends Store<UiManagementState> {

  constructor() {
    super(createInitialState());
  }

}
