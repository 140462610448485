/* eslint-disable @typescript-eslint/naming-convention */
import {environment} from '../environments/environment';
import {appInjector} from '../app-injector';
import {SessionQuery} from '@/core/session/state/session.query';
import {BrowserOptions, Event, EventHint} from '@sentry/angular-ivy';
import packageJson from '../../package.json';
import {getIpAddress} from '@/shared/utils/functions/ip-addresses';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthError} from '@azure/msal-browser';

interface ErrorClass {
  class: any; // Error-class
  condition?: (exception: Error) => boolean; // Optional custom condition
}

const excludedErrorClasses: ErrorClass[] = [
  {
    // Duplicate errors for angular http errors
    class: HttpErrorResponse
  },
  {
    // Authentication errors with msal (will match all msal errors)
    class: AuthError
  }
];

const isErrorExcludedFromSentryUpload = (event: Event, hint: EventHint) => {
  return excludedErrorClasses.some(errorClass => {
    const exception = hint?.originalException;

    // Stop if exception doesn't match error class
    if (!(exception instanceof errorClass.class)) {
      return false;
    }

    // Evaluate custom condition if any is set
    let customConditionResult = true;

    if (exception instanceof Error) {
      customConditionResult = errorClass.condition ? errorClass.condition(exception) : true;
    }

    return customConditionResult;
  });
};

export const beforeSend = async (event: Event, hint: EventHint | undefined) => {
  // Exclude specific errors from being uploaded to sentry
  if (hint && isErrorExcludedFromSentryUpload(event, hint)) {
    return null;
  }

  // Update user ip_address if possible
  const ipAddress = await getIpAddress();
  event.user = {ip_address: ipAddress};

  const sessionQuery = appInjector.get(SessionQuery);
  const userId = sessionQuery.userId?.toString();

  // Update user id to internal user id if possible
  if (userId) {
    event.user.id = userId;
  }

  return event;
};

export const sentryOptions: BrowserOptions = {
  dsn: 'https://23058761f09346f09e5bfc05c23da10d@o1009175.ingest.sentry.io/5973427',
  integrations: [],
  release: `${packageJson.name}@${packageJson.version}`,
  environment: environment.stage,
  beforeSend,
};

