import {ApiResponse} from '@/shared/types/api/api-response';
import {getHttpOptionsWithInclude} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Inject, inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {MsalService} from '@azure/msal-angular';
import {PersistState, withTransaction} from '@datorama/akita';
import {EMPTY, finalize, throwError} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {SessionQuery} from './session.query';
import {SessionStore} from './session.store';
import {TenantService} from './tenant/tenant.service';
import {TenantStore} from './tenant/tenant.store';
import {User} from './user/user.model';

@Injectable({providedIn: 'root'})
export class SessionService {
  private readonly http = inject(HttpClient);
  private readonly sessionStore = inject(SessionStore);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly router = inject(Router);
  private readonly msalService = inject(MsalService);
  private readonly tenantService = inject(TenantService);
  private readonly tenantStore = inject(TenantStore);

  constructor(
    @Inject('persistStorage') private persistStorage: PersistState,
  ) {
  }

  getUser() {
    if (this.sessionQuery.getValue().pendingUserRequest) {
      return EMPTY;
    } else {
      this.sessionStore.update({pendingUserRequest: true});
      const options = getHttpOptionsWithInclude('tenant');

      return this.sessionQuery.selectHasCache().pipe(
        switchMap(hasCache => {
          const apiCall = this.http
            .get<ApiResponse<User>>(environment.api.baseUrl + 'auth/user', options)
            .pipe(
              catchError((error: HttpErrorResponse) => {
                this.router.navigate(['/401']);
                return throwError(error);
              }),
              withTransaction(response => {
                this.updateUser(response['data']);
                this.sessionStore.update({pendingUserRequest: false});
              }),
              switchMap(() => this.tenantService.getById(this.sessionQuery.tenantId)),
              tap(() => this.tenantStore.setActive(this.sessionQuery.tenantId)),
              finalize(() => this.sessionStore.setHasCache(true, {restartTTL: true})),
            );

          return hasCache ? EMPTY : apiCall;
        })
      );
    }
  }

  updateUser(user: User) {
    this.sessionStore.update({user});
  }

  logout() {
    this.persistStorage.clearStore();
    this.msalService.logout();
  }
}
