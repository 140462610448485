import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CountryCodesStore, CountryCodesState } from './country-codes.store';

@Injectable({ providedIn: 'root' })
export class CountryCodesQuery extends QueryEntity<CountryCodesState> {
  constructor(protected store: CountryCodesStore) {
    super(store);
  }
  countryCodes$ = this.selectAll();
}
