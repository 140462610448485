import {OrderActivity} from '../../order-activity.model';
import {OrderLogEntry} from '../../../../components/order-log/order-log.component';
import {OrderLogEntryTitle} from '../../../../enums/order-log-entry-title';
import {LogEntryStates} from '../../enums/log-entry-states.enum';
import {OrderActivityName} from '../../enums/order-activity-names.enum';

export const transformOrderActivitiesForUi = (orderActivities: OrderActivity[]) => {
  let result: OrderLogEntry[] = [];

  // Check if order is rejected, failed or finished
  const orderEndStatus = orderActivities.reverse().find(orderActivity => (
      orderActivity.name === OrderActivityName.rejected
      || orderActivity.name === OrderActivityName.failed
      || orderActivity.name === OrderActivityName.finished
      || orderActivity.name === OrderActivityName.canceled
    )
  );
  // Check if approval workflow is finished
  const orderApprovalWorkflowEndFinished = orderActivities.some(orderActivity => orderActivity.name === OrderActivityName.approvalWorkflowFinished);

  for (const orderActivity of orderActivities.reverse()) {
    const transformOrderActivity: OrderLogEntry = {
      text: orderActivity.display_text
    };

    switch (orderActivity?.name) {
      case OrderActivityName.created:
        transformOrderActivity.status = LogEntryStates.ok;
        transformOrderActivity.title = OrderLogEntryTitle.created;
        break;
      case OrderActivityName.approvalWorkflowStarted:
        transformOrderActivity.listEntries = [];

        transformOrderActivity.title = OrderLogEntryTitle.approvalWorkflowStarted;

        if (orderEndStatus?.name === OrderActivityName.rejected || orderEndStatus?.name === OrderActivityName.canceled) {
          transformOrderActivity.status = LogEntryStates.error;
        } else if (orderApprovalWorkflowEndFinished) {
          transformOrderActivity.status = LogEntryStates.ok;
        } else {
          transformOrderActivity.status = LogEntryStates.pending;
        }
        break;
      case OrderActivityName.approvalWorkflowActivity:
        // TODO: Create/move interface for activity data(s)
        const approvalWorkflowActivityData: { approverComment: string; approverApproved: boolean; offerCreated?: boolean; confirmed?: boolean } = orderActivity?.data;
        if (approvalWorkflowActivityData && (approvalWorkflowActivityData?.approverApproved === true || approvalWorkflowActivityData?.offerCreated === true || approvalWorkflowActivityData?.confirmed === true)) {
          transformOrderActivity.status = LogEntryStates.ok;
        } else {
          transformOrderActivity.status = LogEntryStates.error;
        }

        if (approvalWorkflowActivityData?.approverComment) {
          transformOrderActivity.additionalInformation = {
            title: 'Kommentar',
            text: approvalWorkflowActivityData?.approverComment
          };
        }

        result = result.map(orderLogEntry => {
          if (orderLogEntry.title === OrderLogEntryTitle.approvalWorkflowStarted) {
            orderLogEntry.listEntries = [transformOrderActivity, ...orderLogEntry.listEntries];
          }
          return orderLogEntry;
        });
        continue;
      case OrderActivityName.approvalWorkflowFinished:
        transformOrderActivity.title = OrderLogEntryTitle.approvalWorkflowFinished;

        if (orderEndStatus?.name === OrderActivityName.rejected) {
          transformOrderActivity.status = LogEntryStates.error;
        } else {
          transformOrderActivity.status = LogEntryStates.ok;
        }
        break;
      case OrderActivityName.rejected:
        transformOrderActivity.status = LogEntryStates.error;
        transformOrderActivity.title = OrderLogEntryTitle.rejected;
        break;
      case OrderActivityName.started:
        transformOrderActivity.title = OrderLogEntryTitle.started;

        if (orderEndStatus?.name === OrderActivityName.finished) {
          transformOrderActivity.status = LogEntryStates.ok;
        } else if (orderEndStatus?.name === OrderActivityName.failed) {
          transformOrderActivity.status = LogEntryStates.error;
        } else {
          transformOrderActivity.status = LogEntryStates.pending;
        }
        break;
      case OrderActivityName.failed:
        transformOrderActivity.status = LogEntryStates.error;
        transformOrderActivity.title = OrderLogEntryTitle.failed;
        break;
      case OrderActivityName.canceled:
        transformOrderActivity.status = LogEntryStates.error;
        transformOrderActivity.title = OrderLogEntryTitle.canceled;

        const canceledActivityData: { cancelReason: string; cancelReasonText: string } = orderActivity?.data;
        transformOrderActivity.additionalInformation = {
          title: 'Grund',
          text: canceledActivityData?.cancelReasonText
        };
        break;
      case OrderActivityName.finished:
        transformOrderActivity.status = LogEntryStates.ok;
        transformOrderActivity.title = OrderLogEntryTitle.finished;
        break;
      default:
        continue;
    }
    result = [...result, transformOrderActivity];
  }

  return result.reverse();
};
