import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {OrderTask} from './order-task.model';

export interface OrderTasksState extends EntityState<OrderTask> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'order-tasks'})
export class OrderTasksStore extends EntityStore<OrderTasksState> {

  constructor() {
    super({loading: false});
  }
}
