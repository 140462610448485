import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BillingFrequency } from './billing-frequency.model';

export interface BillingFrequenciesState extends EntityState<BillingFrequency> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'billingFrequencies' })
export class BillingFrequenciesStore extends EntityStore<BillingFrequenciesState> {

  constructor() {
    super({loading: false});
  }

}
