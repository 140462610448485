import {Injectable} from '@angular/core';
import {ID, QueryEntity} from '@datorama/akita';
import {map, switchMap} from 'rxjs/operators';
import {OrdersQuery} from '../orders.query';
import {OrderActivityName} from './enums/order-activity-names.enum';
import {OrderActivitiesState, OrderActivitiesStore} from './order-activities.store';
import {OrderActivity} from './order-activity.model';
import {transformOrderActivitiesForUi} from './utils/functions/transform-order-activities-for-ui';

@Injectable({providedIn: 'root'})
export class OrderActivitiesQuery extends QueryEntity<OrderActivitiesState> {
  isLoading$ = this.ordersQuery.selectLoading();

  orderActivitiesByActiveOrder$ = this.ordersQuery.activeOrder$.pipe(
    switchMap(({id: orderId}) => this.selectOrderActivitiesByOrderId(orderId)),
  );

  isExpansionPanelActiveForActiveOrder$ = this.ordersQuery.activeOrder$.pipe(
    switchMap(({id: orderId}) => this.selectIsExpansionPanelActiveForOrderId(orderId)),
  );

  orderActivitiesLogEntriesByActiveOrder$ = this.ordersQuery.activeOrder$.pipe(
    switchMap(({id: orderId}) => this.selectOrderActivitiesLogEntriesByActiveOrder(orderId)),
  );

  constructor(
    protected store: OrderActivitiesStore,
    private ordersQuery: OrdersQuery
  ) {
    super(store);
  }

  selectIsExpansionPanelActiveForOrderId(orderId: ID) {
    // TODO: Create function to check if order is in one of the end status
    return this.selectOrderActivitiesByOrderId(orderId).pipe(
      map(orderActivities => orderActivities.some(orderLogActivity => (
            orderLogActivity.name === OrderActivityName.finished
            || orderLogActivity.name === OrderActivityName.rejected
            || orderLogActivity.name === OrderActivityName.canceled
          )
        )
      )
    );
  }

  selectOrderActivitiesByOrderId(orderId: ID) {
    return this.selectAll({
      filterBy: (orderActivity: OrderActivity) => orderActivity.order_id === orderId
    });
  }

  selectOrderActivitiesLogEntriesByActiveOrder(orderId: ID) {
    return this.selectOrderActivitiesByOrderId(orderId).pipe(
      map(transformOrderActivitiesForUi),
    );
  }
}
