/* eslint-disable @typescript-eslint/naming-convention */
import {SessionQuery} from '@/core/session/state/session.query';
import {MicrosoftGraphUsersQuery} from '@/shared/state/microsoft-graph-users/microsoft-graph-users.query';
import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {
  createHttpAttributes,
  createHttpIncludes,
  getHttpOptionsWithIncludeAndAttributes,
  getHttpOptionsWithParams,
  getHttpParams
} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {Router} from '@angular/router';
import {ID, PaginationResponse, setLoading} from '@datorama/akita';
import {Observable} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {ShopRouteNames} from '../../constants/routes.constants';
import {SupplierProductRouteNames} from '../constants';
import {SupplierProductFilterPresets} from './enums/supplier-product-filter-presets';
import {ProductCategory} from './product-categories/product-category.model';
import {SupplierProduct, SupplierProductWithRank} from './supplier-product.model';
import {SupplierProductsQuery} from './supplier-products.query';
import {SupplierProductsStore} from './supplier-products.store';

@Injectable({providedIn: 'root'})
export class SupplierProductsService {
  static defaultAttributes = createHttpAttributes([
    'id',
    'supplier_order_number',
    'unit_price',
    'billing_frequency_price',
    'billing_frequency_currency_code_id',
    'billing_frequency_id',
    'supplier_id',
    'status_id',
    'tenant_id',
    'currency_code_id',
    'created_by_id',
    'created_at',
    'updated_at',
    'deleted_at',
    'product_id',
    'is_favorite',
    'promoted_order_number'
  ]);

  static includes = createHttpIncludes([
    'supplier',
    'product',
    'product.images',
    'product.productCategory',
    'product.quantityUnit',
    'product.scaleUnit',
    'product.productAttributes',
    'product.productAttributes.productAttributeTemplate.productAttributeType',
    'product.productAttributes.productAttributeTemplate.quantityUnit',
    'status',
    'createdBy',
    'currencyCode',
    'billingFrequencyCurrencyCode',
    'billingFrequency',
  ]);

  private readonly http = inject(HttpClient);
  private readonly router = inject(Router);
  private readonly snackBar = inject(MatSnackBar);
  private readonly supplierProductsStore = inject(SupplierProductsStore);
  private readonly supplierProductsQuery = inject(SupplierProductsQuery);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly microsoftGraphUsersQuery = inject(MicrosoftGraphUsersQuery);

  get() {
    const options = getHttpOptionsWithIncludeAndAttributes(
      SupplierProductsService.includes,
      SupplierProductsService.defaultAttributes,
      {
        'filter[and][status_id][eq]': 2, // TODO: Use no static id
        tenant_id: this.sessionQuery.tenantId.toString(),
      }
    );

    return this.http
      .get<ApiResponse<SupplierProduct[]>>(environment.api.baseUrl + 'supplier-products', options)
      .pipe(
        setLoading(this.supplierProductsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
        tap(({data: supplierProducts}) => {
          this.supplierProductsStore.set(supplierProducts);
        })
      );
  }

  getBySearchTerm(searchTerm: string) {
    // Reset store for every search
    this.supplierProductsStore.set([]);

    const options = getHttpOptionsWithIncludeAndAttributes(
      SupplierProductsService.includes,
      SupplierProductsService.defaultAttributes,
      {
        'filterPreset': SupplierProductFilterPresets.AdvancedSearch,
        'filterPresetData[searchTerm]': searchTerm,
        'filter[and][status_id][eq]': 2, // TODO: Use no static id
        tenant_id: this.sessionQuery.tenantId.toString(),
      }
    );

    return this.http
      .get<ApiResponse<SupplierProduct[]>>(environment.api.baseUrl + 'supplier-products', options)
      .pipe(
        setLoading(this.supplierProductsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
        tap(({data: supplierProducts}) => {
          this.supplierProductsStore.set(supplierProducts);
        })
      );
  }

  getPromoted() {
    const options = getHttpOptionsWithIncludeAndAttributes(
      SupplierProductsService.includes,
      SupplierProductsService.defaultAttributes,
      {
        'filterPreset': SupplierProductFilterPresets.Promoted,
        tenant_id: this.sessionQuery.tenantId.toString(),
        'limit': 4
      }
    );

    return this.http
      .get<ApiResponse<SupplierProduct[]>>(environment.api.baseUrl + 'supplier-products', options)
      .pipe(
        setLoading(this.supplierProductsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
        tap(({data: supplierProducts}) => {
          this.supplierProductsStore.set(supplierProducts);
        }),
      );
  }

  getByProductCategoryId(productCategoryId: ID) {
    const options = getHttpOptionsWithIncludeAndAttributes(
      SupplierProductsService.includes,
      SupplierProductsService.defaultAttributes,
      {
        'filter[and][product.product_category_id][eq]': productCategoryId.toString(),
        'filter[and][status_id][eq]': 2, // TODO: Use no static id
        tenant_id: this.sessionQuery.tenantId.toString(),
      }
    );

    return this.http
      .get<ApiResponse<SupplierProduct[]>>(environment.api.baseUrl + 'supplier-products', options)
      .pipe(
        setLoading(this.supplierProductsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
        tap(({data: supplierProducts}) => {
          this.supplierProductsStore.set(supplierProducts);
        }),
      );
  }

  getTopSupplierProductsByProductCategoryId(productCategoryId: ProductCategory['id']) {
    const options = getHttpOptionsWithIncludeAndAttributes(
      SupplierProductsService.includes,
      SupplierProductsService.defaultAttributes,
      {
        'filter[and][status_id][eq]': 2,
        tenant_id: this.sessionQuery.tenantId.toString(),
      }
    );

    return this.http.get<ApiResponse<SupplierProductWithRank[]>>(
      environment.api.baseUrl + `product-categories/${productCategoryId}/custom/top-supplier-products`, options
    );
  }

  getPage(page = 1, perPage = 2): Observable<PaginationResponse<SupplierProduct>> {
    let httpParams = getHttpParams({
      include: SupplierProductsService.includes,
      attributes: SupplierProductsService.defaultAttributes,
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    if (httpParams && page) {
      httpParams = httpParams.set('page', page.toString());
    }
    if (httpParams && perPage) {
      httpParams = httpParams.set('per_page', perPage.toString());
    }

    httpParams = httpParams.set('filter[and][status_id][eq]', 2); // TODO: Use no static id
    // .set('filter[and][status_id][eq]', this.supplierProductAvailableStatusesQuery.getStatusIdByName('active'));

    const options = {
      params: httpParams
    };

    return this.http
      .get<ApiResponse<SupplierProduct[]>>(environment.api.baseUrl + 'supplier-products', options)
      .pipe(
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
        map((apiResponse) => {
          return {
            currentPage: apiResponse.meta.current_page,
            perPage: apiResponse.meta.per_page,
            lastPage: apiResponse.meta.last_page,
            data: apiResponse?.data,
            total: apiResponse.meta.total,
            from: apiResponse.meta.from,
            to: apiResponse.meta.to
          } as PaginationResponse<SupplierProduct>;
        }),
      );
  }

  getById(id: number) {
    const options = getHttpOptionsWithIncludeAndAttributes(
      SupplierProductsService.includes,
      SupplierProductsService.defaultAttributes,
      {
        'filter[and][status_id][eq]': 2,
        tenant_id: this.sessionQuery.tenantId.toString(),
      }
    );

    return this.http
      .get<ApiResponse<SupplierProduct>>(environment.api.baseUrl + 'supplier-products/' + id, options)
      .pipe(
        setLoading(this.supplierProductsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
        tap((response) => {
          this.supplierProductsStore.upsert(id, response['data']);
        }),
      );
  }

  getFavorites() {
    const options = getHttpOptionsWithIncludeAndAttributes(
      SupplierProductsService.includes,
      SupplierProductsService.defaultAttributes,
      {
        tenant_id: this.sessionQuery.tenantId.toString(),
      },
    );

    return this.http
      .get<ApiResponse<SupplierProduct[]>>(environment.api.baseUrl + 'supplier-products/custom/favorites', options)
      .pipe(
        setLoading(this.supplierProductsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
        tap(({data: supplierProducts}) => {
          this.supplierProductsStore.upsertMany(supplierProducts);
        })
      );
  }

  add(supplierProduct: SupplierProduct) {
    const options = getHttpOptionsWithIncludeAndAttributes(
      SupplierProductsService.includes,
      SupplierProductsService.defaultAttributes,
      {
        tenant_id: this.sessionQuery.tenantId.toString(),
      },
    );

    return this.http
      .post<ApiResponse<SupplierProduct>>(environment.api.baseUrl + 'supplier-products', supplierProduct, options)
      .pipe(
        setLoading(this.supplierProductsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
        tap(({data: newSupplierProduct}) => {
          this.supplierProductsStore.add(newSupplierProduct);
        }),
      );
  }

  update(id, supplierProduct: Partial<SupplierProduct>, onDb: boolean = false) {
    if (onDb) {
      const options = getHttpOptionsWithIncludeAndAttributes(
        SupplierProductsService.includes,
        SupplierProductsService.defaultAttributes,
        {
          tenant_id: this.sessionQuery.tenantId.toString(),
        },
      );

      this.http
        .put<ApiResponse<SupplierProduct>>(environment.api.baseUrl + 'supplier-products/' + id, supplierProduct, options)
        .pipe(
          setLoading(this.supplierProductsStore),
          catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
          tap((response) => {
            this.supplierProductsStore.update(id, response['data']);
          }),
        ).subscribe();
    } else {
      this.supplierProductsStore.update(id, supplierProduct);
    }
  }

  updateFavoriteStatusForSupplierProduct(supplierProductId: number, isFavorite: boolean) {
    const body = {
      supplier_product_id: supplierProductId.toString(),
    };

    const options = getHttpOptionsWithIncludeAndAttributes(
      SupplierProductsService.includes,
      SupplierProductsService.defaultAttributes,
      {
        tenant_id: this.sessionQuery.tenantId.toString(),
      }
    );

    // Add or Remove supplier product to/from user favorites
    const requestEndpoint = environment.api.baseUrl + 'supplier-products/';
    const request = !isFavorite
      ? this.http.delete<ApiResponse<SupplierProduct>>(requestEndpoint + supplierProductId + '/custom/favorites', options)
      : this.http.post<ApiResponse<SupplierProduct>>(requestEndpoint + 'custom/favorites', body, options);

    return request.pipe(
      setLoading(this.supplierProductsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
      tap((response) => {
        this.supplierProductsStore.update(supplierProductId, response['data']);
      }),
    );
  }

  remove(id: ID, onDb: boolean = false) {
    if (onDb) {
      const options = getHttpOptionsWithParams({
        tenant_id: this.sessionQuery.tenantId.toString(),
      });

      return this.http
        .delete<ApiResponse>(environment.api.baseUrl + 'supplier-products/' + id, options)
        .pipe(
          setLoading(this.supplierProductsStore),
          catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
          tap(() => {
            this.supplierProductsStore.remove(id);
          }),
        );
    } else {
      this.supplierProductsStore.remove(id);
    }
  }

  setActiveSupplierProduct(id: number): void {
    if (!id) {
      this.supplierProductsStore.setActive(null);
    } else if (!this.supplierProductsQuery.hasEntity(id)) {
      this.getById(id).subscribe({
        next: (response) => {
          this.supplierProductsStore.setActive(response['data']?.id);
        }
      });
    } else {
      this.supplierProductsStore.setActive(id);
    }
  }

  updateSearchTerm(searchTerm: string) {
    this.supplierProductsStore.update(({ui}) => ({ui: {...ui, searchTerm}}));
  }

  shareSupplierProduct(supplierProduct: SupplierProduct, recipients: string[], message: string) {
    recipients = recipients
      .map(recipient => this.microsoftGraphUsersQuery.getMicrosoftGraphUserById(recipient)?.mail)
      .filter(recipient => !!recipient);

    const body = {
      recipients,
      message,
    };

    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http.post<ApiResponse>(environment.api.baseUrl + `supplier-products/${supplierProduct.id}/custom/share`, body, options).pipe(
      setLoading(this.supplierProductsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
    );
  }

  navigateToShopSupplierProductDetail(id: number): Promise<boolean> {
    if (id != this.supplierProductsQuery.getActiveId()) {
      this.setActiveSupplierProduct(null);
    }
    return this.router.navigate([
      ShopRouteNames.SHOP + '/' + SupplierProductRouteNames.SUPPLIER_PRODUCT,
      id
    ]);
  }

  navigateToSupplierProductProposal(): Promise<boolean> {
    this.setActiveSupplierProduct(null);
    return this.router.navigate([
      ShopRouteNames.SHOP +
      '/' +
      SupplierProductRouteNames.SUPPLIER_PRODUCT +
      '/' +
      SupplierProductRouteNames.PROPOSAL
    ]);
  }
}
