import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {ProductAttributeTemplatesState, ProductAttributeTemplatesStore} from './product-attribute-templates.store';

@Injectable({providedIn: 'root'})
export class ProductAttributeTemplatesQuery extends QueryEntity<ProductAttributeTemplatesState> {

  constructor(protected store: ProductAttributeTemplatesStore) {
    super(store);
  }

  productAttributeTemplates$ = this.selectAll();
}
