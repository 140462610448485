import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {SupplierProduct} from '../../../state/supplier-product.model';
import {
  SupplierProductsWithSameBaseProductDialogData
} from './models/supplier-products-with-same-base-product-dialog-data.model';
import {
  SupplierProductsWithSameBaseProductDialogOutputData
} from './models/supplier-products-with-same-base-product-dialog-output-data.model';

@Component({
  selector: 'app-supplier-products-with-same-base-product-dialog',
  templateUrl: './supplier-products-with-same-base-product-dialog.component.html',
  styleUrls: ['./supplier-products-with-same-base-product-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupplierProductsWithSameBaseProductDialogComponent {
  private readonly matDialogRef = inject(MatDialogRef<SupplierProductsWithSameBaseProductDialogComponent>);
  private readonly data: SupplierProductsWithSameBaseProductDialogData = inject(MAT_DIALOG_DATA);

  get shopActive() { // Defaults to true
    return this.data.shopActive !== false;
  }

  get supplierProductsWithSameBaseProduct() {
    return this.data.supplierProductsWithSameBaseProduct ?? [];
  }

  addToShoppingCart(supplierProduct: SupplierProduct) {
    this.matDialogRef.close({supplierProduct} as SupplierProductsWithSameBaseProductDialogOutputData);
  }

  cancel() {
    this.matDialogRef.close();
  }
}
