import {Injectable} from '@angular/core';
import {combineQueries, Query} from '@datorama/akita';
import {UiShopState, UiShopStore} from './ui-shop.store';
import {SupplierProductsQuery} from '../supplier-product/state/supplier-products.query';
import {
  SupplierProductImagesQuery
} from '../supplier-product/state/supplier-product-images/supplier-product-images.query';
import {
  SupplierProductAvailableStatusesQuery
} from '../supplier-product/state/supplier-product-available-statuses/supplier-product-available-statuses.query';
import {QuantityUnitsQuery} from '../supplier-product/state/quantity-units/quantity-units.query';
import {ProductCategoriesQuery} from '../supplier-product/state/product-categories/product-categories.query';
import {
  ProductAttributeTemplatesQuery
} from '../supplier-product/state/product-attribute-templates/product-attribute-templates.query';
import {ShoppingCartQuery} from '../shopping-cart/state/shopping-cart.query';
import {OrdersQuery} from '../order/state/orders.query';
import {OrderTasksQuery} from '../order/state/order-tasks/order-tasks.query';
import {OrderItemsQuery} from '../order/state/order-items/order-items.query';
import {OrderAvailableStatusesQuery} from '../order/state/order-available-statuses/order-available-statuses.query';
import {map} from 'rxjs/operators';
import {OrderActivitiesQuery} from '../order/state/order-activities/order-activities.query';
import {OrderStatusesQuery} from '../order/state/order-statuses/order-statuses.query';

@Injectable({providedIn: 'root'})
export class UiShopQuery extends Query<UiShopState> {
  isLoading$ = combineQueries([
    this.supplierProductsQuery.selectLoading(),
    this.supplierProductImagesQuery.selectLoading(),
    this.supplierProductAvailableStatusesQuery.selectLoading(),
    this.quantityUnitsQuery.selectLoading(),
    this.productCategoriesQuery.selectLoading(),
    this.productAttributeTemplatesQuery.selectLoading(),
    this.shoppingCartQuery.selectLoading(),
    this.ordersQuery.selectLoading(),
    this.orderTasksQuery.selectLoading(),
    this.orderItemsQuery.selectLoading(),
    this.orderAvailableStatusesQuery.selectLoading(),
    this.orderActivitiesQuery.selectLoading(),
    this.orderStatusesQuery.selectLoading(),
  ]).pipe(
    map(([supplierProductsLoading,
           supplierProductImagesLoading,
           supplierProductAvailableStatusesLoading,
           quantityUnitsLoading,
           productCategoriesLoading,
           productAttributeTemplatesLoading,
           shoppingCartLoading,
           ordersLoading,
           orderTasksLoading,
           orderItemsLoading,
           orderAvailableStatusesLoading,
           orderActivitiesLoading,
           orderStatusesLoading
         ]) => {
        return supplierProductsLoading
          || supplierProductImagesLoading
          || supplierProductAvailableStatusesLoading
          || quantityUnitsLoading
          || productCategoriesLoading
          || productAttributeTemplatesLoading
          || shoppingCartLoading
          || ordersLoading
          || orderTasksLoading
          || orderItemsLoading
          || orderAvailableStatusesLoading
          || orderActivitiesLoading
          || orderStatusesLoading;
      }
    )
  );

  constructor(
    protected store: UiShopStore,
    private supplierProductsQuery: SupplierProductsQuery,
    private supplierProductImagesQuery: SupplierProductImagesQuery,
    private supplierProductAvailableStatusesQuery: SupplierProductAvailableStatusesQuery,
    private quantityUnitsQuery: QuantityUnitsQuery,
    private productCategoriesQuery: ProductCategoriesQuery,
    private productAttributeTemplatesQuery: ProductAttributeTemplatesQuery,
    private shoppingCartQuery: ShoppingCartQuery,
    private ordersQuery: OrdersQuery,
    private orderTasksQuery: OrderTasksQuery,
    private orderItemsQuery: OrderItemsQuery,
    private orderAvailableStatusesQuery: OrderAvailableStatusesQuery,
    private orderActivitiesQuery: OrderActivitiesQuery,
    private orderStatusesQuery: OrderStatusesQuery
  ) {
    super(store);
  }

}
