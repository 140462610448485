import {CommonModule} from '@angular/common';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {SessionModule} from './session';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SessionModule
  ],
  exports: [
    SessionModule
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() core: CoreModule){
    if(core){
      throw new Error('You should import core module only in the root module');
    }
  }
}
