<div class="mat-title">
    Alle Bezugsquellen zu {{ product?.name }}
</div>

<table
  class="supplier-product-list"
  mat-table
  [dataSource]="supplierProductsWithSameBaseProduct">
    <ng-container matColumnDef="image">
        <th
          mat-header-cell
          *matHeaderCellDef>&nbsp;
        </th>
        <td
          mat-cell
          *matCellDef="let element">
            <img [src]="element.product?.images | productDefaultImageUrl">
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef>
            Produkt
        </th>
        <td
          mat-cell
          *matCellDef="let element">
            <span class="break-anywhere">
            {{ element.product?.name }}
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="supplier">
        <th
          mat-header-cell
          *matHeaderCellDef>
            Bezugsquelle
        </th>
        <td
          mat-cell
          *matCellDef="let element">
            {{ element.supplier?.name }}
        </td>
    </ng-container>

    <ng-container matColumnDef="rating">
        <th
          mat-header-cell
          *matHeaderCellDef>
            Bewertung
        </th>
        <td
          mat-cell
          *matCellDef="let element">
            <span class="eco whitespace-nowrap">
                <ng-container *ngFor="let i of [1, 2, 3, 4]">
                    <mat-icon fontSet="material-icons-round">eco</mat-icon>
                </ng-container>
                <ng-container *ngFor="let i of [1]">
                    <mat-icon fontSet="material-icons-outlined">eco</mat-icon>
                </ng-container>
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="price">
        <th
          mat-header-cell
          *matHeaderCellDef>
            Preis
        </th>
        <td
          mat-cell
          *matCellDef="let element">
            <app-supplier-product-price
              class="whitespace-nowrap"
              [supplierProduct]="element"/>
        </td>
    </ng-container>

    <ng-container matColumnDef="addToBasket">
        <th
          mat-header-cell
          *matHeaderCellDef>&nbsp;
        </th>
        <td
          mat-cell
          *matCellDef="let element">
            <button
              mat-icon-button
              mat-raised-button
              color="primary"
              matTooltip="In den Einkaufswagen"
              (click)="addToShoppingCart(element.id)">
                <mat-icon>shopping_cart</mat-icon>
            </button>
        </td>
    </ng-container>

    <ng-container matColumnDef="goToProduct">
        <th
          mat-header-cell
          *matHeaderCellDef>&nbsp;
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="text-right">
            <a
              *ngIf="selectedSupplierProduct?.id !== element.id; else currentSupplierProduct"
              mat-icon-button
              (click)="navigateToSupplierProduct(element)"
              [routerLink]="['/shop/supplier-product/' + element.id]">
                <mat-icon>arrow_forward</mat-icon>
            </a>

            <ng-template #currentSupplierProduct>
                <span class="mat-hint">Ausgewählt</span>
            </ng-template>
        </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumnsForSupplierProductsWithSameBaseProduct"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumnsForSupplierProductsWithSameBaseProduct;"></tr>
</table>
