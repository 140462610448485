import {HttpErrorResponse} from '@angular/common/http';
import {AbstractControl} from '@angular/forms';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {Store} from '@datorama/akita';
import {throwError} from 'rxjs';
import * as translation from '../../translations/de.json';

export const getFirstErrorOfProperty = (name: string, serverErrors: [] = null, control: AbstractControl = null) => {
  const errors: string[] = [];
  let attributeDisplayName: string;

  if (translation && (translation as any).default) {
    attributeDisplayName = (translation as any).default[name];
  } else {
    attributeDisplayName = name;
  }

  if (control) {
    if (control.hasError('required')) {
      errors.push(attributeDisplayName + ' muss ausgefüllt werden.');
    } else if (control.hasError('maxlength')) {
      errors.push(attributeDisplayName + ' ist zu lang.');
    }
  }

  if (serverErrors && serverErrors[name]) {
    errors.push(...serverErrors[name]);
  }

  if (errors && errors[0]) {
    return errors[0];
  } else {
    return '';
  }
};

export function handleError(error: HttpErrorResponse, snackBar?: MatSnackBar, store?: Store) {
  if (store) {
    store.setLoading(false);
  }

  if (error.status === 0) {
    // A client-side or network error occurred. Handle it accordingly.
    // console.error('An error occurred:', error.error);

    if (snackBar) {
      snackBar.open('Aktion konnte nicht ausgeführt werden!', 'Schließen', {
        panelClass: 'snackbar-error',
      });
    }
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    if (snackBar) {
      snackBar.open('Aktion konnte nicht ausgeführt werden!', 'Schließen', {
        panelClass: 'snackbar-error',
      });
    }
  }
  // Return an observable with a user-facing error message.
  return throwError(
    error);
}
