import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {filter, map} from 'rxjs/operators';
import {CurrencyCodesState, CurrencyCodesStore} from './currency-codes.store';

@Injectable({providedIn: 'root'})
export class CurrencyCodesQuery extends QueryEntity<CurrencyCodesState> {
  currencyCodes$ = this.selectAll();

  eur$ = this.selectAll({
    filterBy: ({name}) => name === 'EUR'
  }).pipe(
    filter(currencyCodes => currencyCodes.length > 0),
    map(currencyCodes => currencyCodes[0]),
  );

  constructor(protected store: CurrencyCodesStore) {
    super(store);
  }

  selectCurrencyCodeById(currencyCodeId: number) {
    return this.selectAll().pipe(
      map(currencyCodes => currencyCodes.find(({id}) => id === currencyCodeId)),
    );
  }
}
