import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {first, map} from 'rxjs/operators';
import {SupplierProductImagesState, SupplierProductImagesStore} from './supplier-product-images.store';

@Injectable({providedIn: 'root'})
export class SupplierProductImagesQuery extends QueryEntity<SupplierProductImagesState> {
  constructor(protected store: SupplierProductImagesStore) {
    super(store);
  }

  getDefaultImageUrlBySupplierProductId(id: number) {
    return this.selectAll({
      filterBy: [
        ({supplier_product_id}) => supplier_product_id === id,
        ({name}) => name === 'default'
      ]
    }).pipe(
      first(),
      map(x => (Array.isArray(x) && x.length) ? x[0]?.urls?.original : './assets/default-placeholder.jpg') // Get first entity
    );
  }
}
