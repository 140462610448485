import {Permission} from '../../permission.model';

export const getAllowedScopeForPermission = (permissionToCheck: Partial<Permission>) => {
  switch (permissionToCheck?.scope) {
    case 'user':
      return ['*', 'tenant', 'user'];
    case 'tenant':
      return ['*', 'tenant'];
    case '*':
      return ['*'];
    default:
      return null;
  }
}
