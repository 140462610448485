import {
  ProductCategoryApproversQuery
} from '@/shared/state/product-category-approvers/product-category-approvers.query';
import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {ProductCategoriesState, ProductCategoriesStore} from './product-categories.store';
import {ProductCategory} from './product-category.model';

@Injectable({providedIn: 'root'})
export class ProductCategoriesQuery extends QueryEntity<ProductCategoriesState> {
  productCategories$ = this.selectAll();

  constructor(
    protected store: ProductCategoriesStore,
    private productCategoryApproversQuery: ProductCategoryApproversQuery
  ) {
    super(store);
  }

  selectProductCategory(id: number) {
    return this.selectEntity(id);
  }

  selectProductCategoryApproversOfProductCategoryById(id: ProductCategory['id']) {
    return this.productCategoryApproversQuery.selectAll({
      filterBy: ({product_category_id}) => product_category_id == id
    });
  }
}
