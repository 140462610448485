import {Pipe, PipeTransform} from '@angular/core';
import {SupplierProduct} from '../state/supplier-product.model';

@Pipe({
  name: 'supplierProductWithLowestUnitPrice'
})
export class SupplierProductWithLowestUnitPricePipe implements PipeTransform {
  transform(supplierProducts: SupplierProduct[]) {
    const hasProductWithUnitPrice = supplierProducts.some(({unit_price}) =>
      unit_price !== null
    );

    if (!hasProductWithUnitPrice) {
      return null;
    }

    return supplierProducts.reduce((cheapestProduct, supplierProduct) => {
      return supplierProduct.unit_price && +cheapestProduct.unit_price < +supplierProduct.unit_price
        ? cheapestProduct
        : supplierProduct;
    });
  }
}
