<mat-toolbar
  class="flex justify-between items-center fix-nav"
  color="accent">
    <button
      type="button"
      mat-icon-button
      class="sidenavToggle"
      (click)="toggleSidebar()">
        <mat-icon>{{ isSidenavOpen ? "close" : "menu" }}</mat-icon>
    </button>

    <button
      type="button"
      mat-icon-button
      class="faq"
      matTooltip="FAQ"
      (click)="navigateToFaq()">
        <mat-icon>help_outline</mat-icon>
    </button>
</mat-toolbar>

<ng-container *ngIf="isLoading$ | async">
    <mat-progress-bar
      mode="indeterminate"
      color="primary"/>
</ng-container>
