import {Injectable} from '@angular/core';
import {combineQueries, QueryEntity} from '@datorama/akita';
import {ProductsState, ProductsStore} from './products.store';
import {StatusesQuery} from '@/shared/state/status/statuses.query';
import {map} from 'rxjs/operators';
import {Product} from './product.model';
import {addStatusToSupplierProduct} from './helpers/functions/add-status-to-suppliers';
import {DqnAutocompleteOption} from '@dqn/components-library/autocomplete';

@Injectable({providedIn: 'root'})
export class ProductsQuery extends QueryEntity<ProductsState> {
  isLoading$ = this.selectLoading();

  products$ = combineQueries([
    this.selectAll(),
    this.statusesQuery.statuses$,
  ]).pipe(
    map(([products, statuses]) => statuses
      ? products.map(product => ({
        ...product,
        suppliers: addStatusToSupplierProduct(product.suppliers, statuses),
      } as Product))
      : products
    ),
  );

  activeProduct$ = this.selectActive();

  constructor(
    protected store: ProductsStore,
    private statusesQuery: StatusesQuery,
  ) {
    super(store);
  }

  selectProductById(productId: number) {
    return this.selectEntity(productId);
  }

  selectProductsForCategory(categoryId: number) {
    return this.products$.pipe(
      map(products => products.filter(product => product.product_category_id === categoryId)),
    );
  }

  selectProductsForCategoryAsDqnAutocompleteOptions(categoryId: number) {
    return this.selectProductsForCategory(categoryId).pipe(
      map(products => products.map(({id, name}) => ({
        value: id,
        title: name,
      } as DqnAutocompleteOption)))
    );
  }
}
